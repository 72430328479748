.e-btn,
.e-css.e-btn {
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-font-smoothing: antialiased;
  border: 1px solid;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1.143em;
  outline: none;
  padding: 6px 12px 4px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.e-btn:disabled,
.e-css.e-btn:disabled {
  cursor: default;
}

.e-btn:hover, .e-btn:focus,
.e-css.e-btn:hover,
.e-css.e-btn:focus {
  text-decoration: none;
}

.e-btn::-moz-focus-inner,
.e-css.e-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.e-btn .e-btn-icon,
.e-css.e-btn .e-btn-icon {
  display: inline-block;
  font-size: 12px;
  margin-top: -2px;
  vertical-align: middle;
  width: 1em;
}
.e-btn .e-btn-icon.e-icon-left,
.e-css.e-btn .e-btn-icon.e-icon-left {
  margin-left: -0.6667em;
  width: 2.25em;
}
.e-btn .e-btn-icon.e-icon-right,
.e-css.e-btn .e-btn-icon.e-icon-right {
  width: 2.25em;
  margin-right: -0.6667em;
}
.e-btn .e-btn-icon.e-icon-top,
.e-css.e-btn .e-btn-icon.e-icon-top {
  padding-bottom: 6px;
  display: block;
  margin-top: 0;
  width: auto;
}
.e-btn .e-btn-icon.e-icon-bottom,
.e-css.e-btn .e-btn-icon.e-icon-bottom {
  padding-top: 6px;
  display: block;
  margin-top: 0;
  width: auto;
}

.e-btn.e-icon-btn,
.e-css.e-btn.e-icon-btn {
  padding: 6px 7px 4px;
}

.e-btn.e-top-icon-btn, .e-btn.e-bottom-icon-btn,
.e-css.e-btn.e-top-icon-btn,
.e-css.e-btn.e-bottom-icon-btn {
  line-height: 1;
  padding: 12px 12px;
}

.e-btn.e-round,
.e-css.e-btn.e-round {
  border-radius: 50%;
  height: 3em;
  line-height: 1;
  padding: 0;
  width: 3em;
}
.e-btn.e-round .e-btn-icon,
.e-css.e-btn.e-round .e-btn-icon {
  font-size: 14px;
  line-height: 2.8572em;
  margin-top: 0;
  width: auto;
}

.e-btn.e-rtl .e-icon-right,
.e-css.e-btn.e-rtl .e-icon-right {
  margin-left: -0.6667em;
  margin-right: 0;
}
.e-btn.e-rtl .e-icon-left,
.e-css.e-btn.e-rtl .e-icon-left {
  margin-left: 0;
  margin-right: -0.6667em;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
  border: 1px solid;
}

.e-btn.e-small,
.e-css.e-btn.e-small {
  font-size: 12px;
  line-height: 1.5834em;
  padding: 2px 12px 1px;
}
.e-btn.e-small .e-btn-icon,
.e-css.e-btn.e-small .e-btn-icon {
  font-size: 11px;
  width: 1.091em;
}
.e-btn.e-small .e-btn-icon.e-icon-left,
.e-css.e-btn.e-small .e-btn-icon.e-icon-left {
  margin-left: -0.7273em;
  width: 2.182em;
}
.e-btn.e-small .e-btn-icon.e-icon-right,
.e-css.e-btn.e-small .e-btn-icon.e-icon-right {
  margin-right: -0.7273em;
  width: 2.182em;
}
.e-btn.e-small .e-btn-icon.e-icon-top,
.e-css.e-btn.e-small .e-btn-icon.e-icon-top {
  padding-bottom: 6px;
  width: auto;
}
.e-btn.e-small .e-btn-icon.e-icon-bottom,
.e-css.e-btn.e-small .e-btn-icon.e-icon-bottom {
  padding-top: 6px;
  width: auto;
}
.e-btn.e-small.e-icon-btn,
.e-css.e-btn.e-small.e-icon-btn {
  padding: 2px 5px 1px;
}
.e-btn.e-small.e-top-icon-btn, .e-btn.e-small.e-bottom-icon-btn,
.e-css.e-btn.e-small.e-top-icon-btn,
.e-css.e-btn.e-small.e-bottom-icon-btn {
  line-height: 1;
  padding: 12px 12px;
}
.e-btn.e-small.e-round,
.e-css.e-btn.e-small.e-round {
  height: 2.5em;
  line-height: 1;
  padding: 0;
  width: 2.5em;
}
.e-btn.e-small.e-round .e-btn-icon,
.e-css.e-btn.e-small.e-round .e-btn-icon {
  font-size: 12px;
  line-height: 2.3334em;
  width: auto;
}
.e-btn.e-small.e-rtl .e-icon-right,
.e-css.e-btn.e-small.e-rtl .e-icon-right {
  margin-left: -0.7273em;
  margin-right: 0;
}
.e-btn.e-small.e-rtl .e-icon-left,
.e-css.e-btn.e-small.e-rtl .e-icon-left {
  margin-left: 0;
  margin-right: -0.7273em;
}

.e-btn.e-block,
.e-css.e-btn.e-block {
  display: block;
  width: 100%;
}

.e-small .e-btn,
.e-small.e-btn,
.e-small .e-css.e-btn,
.e-small.e-css.e-btn {
  font-size: 12px;
  line-height: 1.5834em;
  padding: 2px 12px 1px;
}
.e-small .e-btn .e-btn-icon,
.e-small.e-btn .e-btn-icon,
.e-small .e-css.e-btn .e-btn-icon,
.e-small.e-css.e-btn .e-btn-icon {
  font-size: 11px;
  width: 1.091em;
}
.e-small .e-btn .e-btn-icon.e-icon-left,
.e-small.e-btn .e-btn-icon.e-icon-left,
.e-small .e-css.e-btn .e-btn-icon.e-icon-left,
.e-small.e-css.e-btn .e-btn-icon.e-icon-left {
  margin-left: -0.7273em;
  width: 2.182em;
}
.e-small .e-btn .e-btn-icon.e-icon-right,
.e-small.e-btn .e-btn-icon.e-icon-right,
.e-small .e-css.e-btn .e-btn-icon.e-icon-right,
.e-small.e-css.e-btn .e-btn-icon.e-icon-right {
  margin-right: -0.7273em;
  width: 2.182em;
}
.e-small .e-btn .e-btn-icon.e-icon-top,
.e-small.e-btn .e-btn-icon.e-icon-top,
.e-small .e-css.e-btn .e-btn-icon.e-icon-top,
.e-small.e-css.e-btn .e-btn-icon.e-icon-top {
  padding-bottom: 6px;
  width: auto;
}
.e-small .e-btn .e-btn-icon.e-icon-bottom,
.e-small.e-btn .e-btn-icon.e-icon-bottom,
.e-small .e-css.e-btn .e-btn-icon.e-icon-bottom,
.e-small.e-css.e-btn .e-btn-icon.e-icon-bottom {
  padding-top: 6px;
  width: auto;
}
.e-small .e-btn.e-icon-btn,
.e-small.e-btn.e-icon-btn,
.e-small .e-css.e-btn.e-icon-btn,
.e-small.e-css.e-btn.e-icon-btn {
  padding: 2px 5px 1px;
}
.e-small .e-btn.e-top-icon-btn, .e-small .e-btn.e-bottom-icon-btn,
.e-small.e-btn.e-top-icon-btn,
.e-small.e-btn.e-bottom-icon-btn,
.e-small .e-css.e-btn.e-top-icon-btn,
.e-small .e-css.e-btn.e-bottom-icon-btn,
.e-small.e-css.e-btn.e-top-icon-btn,
.e-small.e-css.e-btn.e-bottom-icon-btn {
  line-height: 1;
  padding: 12px 12px;
}
.e-small .e-btn.e-round,
.e-small.e-btn.e-round,
.e-small .e-css.e-btn.e-round,
.e-small.e-css.e-btn.e-round {
  height: 2.5em;
  line-height: 1;
  padding: 0;
  width: 2.5em;
  border-radius: 50%;
}
.e-small .e-btn.e-round .e-btn-icon,
.e-small.e-btn.e-round .e-btn-icon,
.e-small .e-css.e-btn.e-round .e-btn-icon,
.e-small.e-css.e-btn.e-round .e-btn-icon {
  font-size: 12px;
  line-height: 2.3334em;
  width: auto;
}
.e-small .e-btn.e-rtl .e-icon-right,
.e-small.e-btn.e-rtl .e-icon-right,
.e-small .e-css.e-btn.e-rtl .e-icon-right,
.e-small.e-css.e-btn.e-rtl .e-icon-right {
  margin-left: -0.7273em;
  margin-right: 0;
}
.e-small .e-btn.e-rtl .e-icon-left,
.e-small.e-btn.e-rtl .e-icon-left,
.e-small .e-css.e-btn.e-rtl .e-icon-left,
.e-small.e-css.e-btn.e-rtl .e-icon-left {
  margin-left: 0;
  margin-right: -0.7273em;
}

.e-bigger.e-small .e-btn,
.e-bigger.e-small .e-btn,
.e-bigger.e-small .e-css.e-btn,
.e-bigger.e-small.e-css.e-btn {
  font-size: 14px;
  line-height: 2em;
  padding: 3px 16px 1px;
}
.e-bigger.e-small .e-btn .e-btn-icon,
.e-bigger.e-small .e-btn .e-btn-icon,
.e-bigger.e-small .e-css.e-btn .e-btn-icon,
.e-bigger.e-small.e-css.e-btn .e-btn-icon {
  font-size: 12px;
  width: 1em;
}
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-left,
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-left,
.e-bigger.e-small .e-css.e-btn .e-btn-icon.e-icon-left,
.e-bigger.e-small.e-css.e-btn .e-btn-icon.e-icon-left {
  margin-left: -1em;
  width: 2.6667em;
}
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-right,
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-right,
.e-bigger.e-small .e-css.e-btn .e-btn-icon.e-icon-right,
.e-bigger.e-small.e-css.e-btn .e-btn-icon.e-icon-right {
  margin-right: -1em;
  width: 2.6667em;
}
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-top,
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-top,
.e-bigger.e-small .e-css.e-btn .e-btn-icon.e-icon-top,
.e-bigger.e-small.e-css.e-btn .e-btn-icon.e-icon-top {
  padding-bottom: 6px;
  width: auto;
}
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-bottom,
.e-bigger.e-small .e-btn .e-btn-icon.e-icon-bottom,
.e-bigger.e-small .e-css.e-btn .e-btn-icon.e-icon-bottom,
.e-bigger.e-small.e-css.e-btn .e-btn-icon.e-icon-bottom {
  padding-top: 6px;
  width: auto;
}
.e-bigger.e-small .e-btn.e-icon-btn,
.e-bigger.e-small .e-btn.e-icon-btn,
.e-bigger.e-small .e-css.e-btn.e-icon-btn,
.e-bigger.e-small.e-css.e-btn.e-icon-btn {
  padding: 3px 10px 1px;
}
.e-bigger.e-small .e-btn.e-top-icon-btn, .e-bigger.e-small .e-btn.e-bottom-icon-btn,
.e-bigger.e-small .e-btn.e-top-icon-btn,
.e-bigger.e-small .e-btn.e-bottom-icon-btn,
.e-bigger.e-small .e-css.e-btn.e-top-icon-btn,
.e-bigger.e-small .e-css.e-btn.e-bottom-icon-btn,
.e-bigger.e-small.e-css.e-btn.e-top-icon-btn,
.e-bigger.e-small.e-css.e-btn.e-bottom-icon-btn {
  line-height: 1;
  padding: 16px 16px;
}
.e-bigger.e-small .e-btn.e-round,
.e-bigger.e-small .e-btn.e-round,
.e-bigger.e-small .e-css.e-btn.e-round,
.e-bigger.e-small.e-css.e-btn.e-round {
  height: 2.8572em;
  line-height: 1;
  padding: 0;
  width: 2.8572em;
}
.e-bigger.e-small .e-btn.e-round .e-btn-icon,
.e-bigger.e-small .e-btn.e-round .e-btn-icon,
.e-bigger.e-small .e-css.e-btn.e-round .e-btn-icon,
.e-bigger.e-small.e-css.e-btn.e-round .e-btn-icon {
  font-size: 14px;
  line-height: 2.7143em;
  width: auto;
}
.e-bigger.e-small .e-btn.e-rtl .e-icon-right,
.e-bigger.e-small .e-btn.e-rtl .e-icon-right,
.e-bigger.e-small .e-css.e-btn.e-rtl .e-icon-right,
.e-bigger.e-small.e-css.e-btn.e-rtl .e-icon-right {
  margin-left: -1em;
  margin-right: 0;
}
.e-bigger.e-small .e-btn.e-rtl .e-icon-left,
.e-bigger.e-small .e-btn.e-rtl .e-icon-left,
.e-bigger.e-small .e-css.e-btn.e-rtl .e-icon-left,
.e-bigger.e-small.e-css.e-btn.e-rtl .e-icon-left {
  margin-left: 0;
  margin-right: -1em;
}

.e-bigger .e-btn,
.e-bigger .e-btn,
.e-bigger .e-css.e-btn,
.e-bigger.e-css.e-btn {
  font-size: 14px;
  line-height: 2em;
  padding: 4px 16px 2px;
}
.e-bigger .e-btn .e-btn-icon,
.e-bigger .e-btn .e-btn-icon,
.e-bigger .e-css.e-btn .e-btn-icon,
.e-bigger.e-css.e-btn .e-btn-icon {
  font-size: 12px;
  width: 1em;
}
.e-bigger .e-btn .e-btn-icon.e-icon-left,
.e-bigger .e-btn .e-btn-icon.e-icon-left,
.e-bigger .e-css.e-btn .e-btn-icon.e-icon-left,
.e-bigger.e-css.e-btn .e-btn-icon.e-icon-left {
  margin-left: -1em;
  width: 3em;
}
.e-bigger .e-btn .e-btn-icon.e-icon-right,
.e-bigger .e-btn .e-btn-icon.e-icon-right,
.e-bigger .e-css.e-btn .e-btn-icon.e-icon-right,
.e-bigger.e-css.e-btn .e-btn-icon.e-icon-right {
  margin-right: -1em;
  width: 3em;
}
.e-bigger .e-btn .e-btn-icon.e-icon-top,
.e-bigger .e-btn .e-btn-icon.e-icon-top,
.e-bigger .e-css.e-btn .e-btn-icon.e-icon-top,
.e-bigger.e-css.e-btn .e-btn-icon.e-icon-top {
  padding-bottom: 8px;
  width: auto;
}
.e-bigger .e-btn .e-btn-icon.e-icon-bottom,
.e-bigger .e-btn .e-btn-icon.e-icon-bottom,
.e-bigger .e-css.e-btn .e-btn-icon.e-icon-bottom,
.e-bigger.e-css.e-btn .e-btn-icon.e-icon-bottom {
  padding-top: 8px;
  width: auto;
}
.e-bigger .e-btn.e-icon-btn,
.e-bigger .e-btn.e-icon-btn,
.e-bigger .e-css.e-btn.e-icon-btn,
.e-bigger.e-css.e-btn.e-icon-btn {
  padding: 4px 11px 2px;
}
.e-bigger .e-btn.e-top-icon-btn, .e-bigger .e-btn.e-bottom-icon-btn,
.e-bigger .e-btn.e-top-icon-btn,
.e-bigger .e-btn.e-bottom-icon-btn,
.e-bigger .e-css.e-btn.e-top-icon-btn,
.e-bigger .e-css.e-btn.e-bottom-icon-btn,
.e-bigger.e-css.e-btn.e-top-icon-btn,
.e-bigger.e-css.e-btn.e-bottom-icon-btn {
  line-height: 1;
  padding: 16px 16px;
}
.e-bigger .e-btn.e-round,
.e-bigger .e-btn.e-round,
.e-bigger .e-css.e-btn.e-round,
.e-bigger.e-css.e-btn.e-round {
  height: 3.7143em;
  line-height: 1;
  padding: 0;
  width: 3.7143em;
}
.e-bigger .e-btn.e-round .e-btn-icon,
.e-bigger .e-btn.e-round .e-btn-icon,
.e-bigger .e-css.e-btn.e-round .e-btn-icon,
.e-bigger.e-css.e-btn.e-round .e-btn-icon {
  font-size: 16px;
  line-height: 3.125em;
  width: auto;
}
.e-bigger .e-btn.e-rtl .e-icon-right,
.e-bigger .e-btn.e-rtl .e-icon-right,
.e-bigger .e-css.e-btn.e-rtl .e-icon-right,
.e-bigger.e-css.e-btn.e-rtl .e-icon-right {
  margin-left: -1em;
  margin-right: 0;
}
.e-bigger .e-btn.e-rtl .e-icon-left,
.e-bigger .e-btn.e-rtl .e-icon-left,
.e-bigger .e-css.e-btn.e-rtl .e-icon-left,
.e-bigger.e-css.e-btn.e-rtl .e-icon-left {
  margin-left: 0;
  margin-right: -1em;
}
.e-bigger .e-btn.e-small,
.e-bigger .e-btn.e-small,
.e-bigger .e-css.e-btn.e-small,
.e-bigger.e-css.e-btn.e-small {
  font-size: 14px;
  line-height: 2em;
  padding: 3px 16px 1px;
}
.e-bigger .e-btn.e-small .e-btn-icon,
.e-bigger .e-btn.e-small .e-btn-icon,
.e-bigger .e-css.e-btn.e-small .e-btn-icon,
.e-bigger.e-css.e-btn.e-small .e-btn-icon {
  font-size: 12px;
  width: 1em;
}
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-left,
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-left,
.e-bigger .e-css.e-btn.e-small .e-btn-icon.e-icon-left,
.e-bigger.e-css.e-btn.e-small .e-btn-icon.e-icon-left {
  margin-left: -1em;
  width: 2.6667em;
}
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-right,
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-right,
.e-bigger .e-css.e-btn.e-small .e-btn-icon.e-icon-right,
.e-bigger.e-css.e-btn.e-small .e-btn-icon.e-icon-right {
  margin-right: -1em;
  width: 2.6667em;
}
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-top,
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-top,
.e-bigger .e-css.e-btn.e-small .e-btn-icon.e-icon-top,
.e-bigger.e-css.e-btn.e-small .e-btn-icon.e-icon-top {
  padding-bottom: 6px;
  width: auto;
}
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-bottom,
.e-bigger .e-btn.e-small .e-btn-icon.e-icon-bottom,
.e-bigger .e-css.e-btn.e-small .e-btn-icon.e-icon-bottom,
.e-bigger.e-css.e-btn.e-small .e-btn-icon.e-icon-bottom {
  padding-top: 6px;
  width: auto;
}
.e-bigger .e-btn.e-small.e-icon-btn,
.e-bigger .e-btn.e-small.e-icon-btn,
.e-bigger .e-css.e-btn.e-small.e-icon-btn,
.e-bigger.e-css.e-btn.e-small.e-icon-btn {
  padding: 3px 10px 1px;
}
.e-bigger .e-btn.e-small.e-top-icon-btn, .e-bigger .e-btn.e-small.e-bottom-icon-btn,
.e-bigger .e-btn.e-small.e-top-icon-btn,
.e-bigger .e-btn.e-small.e-bottom-icon-btn,
.e-bigger .e-css.e-btn.e-small.e-top-icon-btn,
.e-bigger .e-css.e-btn.e-small.e-bottom-icon-btn,
.e-bigger.e-css.e-btn.e-small.e-top-icon-btn,
.e-bigger.e-css.e-btn.e-small.e-bottom-icon-btn {
  line-height: 1;
  padding: 16px 16px;
}
.e-bigger .e-btn.e-small.e-round,
.e-bigger .e-btn.e-small.e-round,
.e-bigger .e-css.e-btn.e-small.e-round,
.e-bigger.e-css.e-btn.e-small.e-round {
  height: 2.8572em;
  line-height: 1;
  padding: 0;
  width: 2.8572em;
}
.e-bigger .e-btn.e-small.e-round .e-btn-icon,
.e-bigger .e-btn.e-small.e-round .e-btn-icon,
.e-bigger .e-css.e-btn.e-small.e-round .e-btn-icon,
.e-bigger.e-css.e-btn.e-small.e-round .e-btn-icon {
  font-size: 14px;
  line-height: 2.7143em;
  width: auto;
}
.e-bigger .e-btn.e-small.e-rtl .e-icon-right,
.e-bigger .e-btn.e-small.e-rtl .e-icon-right,
.e-bigger .e-css.e-btn.e-small.e-rtl .e-icon-right,
.e-bigger.e-css.e-btn.e-small.e-rtl .e-icon-right {
  margin-left: -1em;
  margin-right: 0;
}
.e-bigger .e-btn.e-small.e-rtl .e-icon-left,
.e-bigger .e-btn.e-small.e-rtl .e-icon-left,
.e-bigger .e-css.e-btn.e-small.e-rtl .e-icon-left,
.e-bigger.e-css.e-btn.e-small.e-rtl .e-icon-left {
  margin-left: 0;
  margin-right: -1em;
}

/* stylelint-disable property-no-vendor-prefix */
.e-btn,
.e-css.e-btn {
  -webkit-tap-highlight-color: transparent;
  background: #fafafa;
  border-color: transparent;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.e-btn:hover,
.e-css.e-btn:hover {
  background: rgba(226, 226, 226, 0.9844);
  border-color: transparent;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.e-btn:focus,
.e-css.e-btn:focus {
  background: rgba(0, 0, 0, 0.18);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  outline: #fafafa 0 solid;
  outline-offset: 0;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.e-btn:active,
.e-css.e-btn:active {
  background: rgba(184, 184, 184, 0.9584);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  outline: #fafafa 0 solid;
  outline-offset: 0;
}

.e-btn.e-active,
.e-css.e-btn.e-active {
  background: rgba(184, 184, 184, 0.9584);
  border-color: transparent;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.e-btn:disabled, .e-btn.e-disabled,
.e-css.e-btn:disabled,
.e-css.e-btn.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}

.e-btn .e-ripple-element,
.e-css.e-btn .e-ripple-element {
  background: rgba(0, 0, 0, 0.24);
}

.e-btn.e-round, .e-btn.e-round-edge,
.e-css.e-btn.e-round,
.e-css.e-btn.e-round-edge {
  background: #fafafa;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.e-btn.e-round:hover, .e-btn.e-round-edge:hover,
.e-css.e-btn.e-round:hover,
.e-css.e-btn.e-round-edge:hover {
  background: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}
.e-btn.e-round:focus, .e-btn.e-round-edge:focus,
.e-css.e-btn.e-round:focus,
.e-css.e-btn.e-round-edge:focus {
  background: rgba(0, 0, 0, 0.18);
  border-color: transparent;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  outline: #fafafa 0 solid;
  outline-offset: 0;
}
.e-btn.e-round:active, .e-btn.e-round-edge:active,
.e-css.e-btn.e-round:active,
.e-css.e-btn.e-round-edge:active {
  background: rgba(184, 184, 184, 0.9584);
  border-color: transparent;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  outline: #fafafa 0 solid;
  outline-offset: 0;
}
.e-btn.e-round:disabled, .e-btn.e-round.e-disabled, .e-btn.e-round-edge:disabled, .e-btn.e-round-edge.e-disabled,
.e-css.e-btn.e-round:disabled,
.e-css.e-btn.e-round.e-disabled,
.e-css.e-btn.e-round-edge:disabled,
.e-css.e-btn.e-round-edge.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-round.e-primary:hover, .e-btn.e-round-edge.e-primary:hover,
.e-css.e-btn.e-round.e-primary:hover,
.e-css.e-btn.e-round-edge.e-primary:hover {
  border-color: #e3165b;
}
.e-btn.e-round.e-primary:focus, .e-btn.e-round-edge.e-primary:focus,
.e-css.e-btn.e-round.e-primary:focus,
.e-css.e-btn.e-round-edge.e-primary:focus {
  outline: #fafafa 0 solid;
}
.e-btn.e-round.e-success:hover, .e-btn.e-round-edge.e-success:hover,
.e-css.e-btn.e-round.e-success:hover,
.e-css.e-btn.e-round-edge.e-success:hover {
  border-color: #4d841d;
}
.e-btn.e-round.e-success:focus, .e-btn.e-round-edge.e-success:focus,
.e-css.e-btn.e-round.e-success:focus,
.e-css.e-btn.e-round-edge.e-success:focus {
  outline: #fafafa 0 solid;
}
.e-btn.e-round.e-info:hover, .e-btn.e-round-edge.e-info:hover,
.e-css.e-btn.e-round.e-info:hover,
.e-css.e-btn.e-round-edge.e-info:hover {
  border-color: #0378d5;
}
.e-btn.e-round.e-info:focus, .e-btn.e-round-edge.e-info:focus,
.e-css.e-btn.e-round.e-info:focus,
.e-css.e-btn.e-round-edge.e-info:focus {
  outline: #fafafa 0 solid;
}
.e-btn.e-round.e-warning:hover, .e-btn.e-round-edge.e-warning:hover,
.e-css.e-btn.e-round.e-warning:hover,
.e-css.e-btn.e-round-edge.e-warning:hover {
  border-color: #c15700;
}
.e-btn.e-round.e-warning:focus, .e-btn.e-round-edge.e-warning:focus,
.e-css.e-btn.e-round.e-warning:focus,
.e-css.e-btn.e-round-edge.e-warning:focus {
  outline: #fafafa 0 solid;
}
.e-btn.e-round.e-danger:hover, .e-btn.e-round-edge.e-danger:hover,
.e-css.e-btn.e-round.e-danger:hover,
.e-css.e-btn.e-round-edge.e-danger:hover {
  border-color: #d64113;
}
.e-btn.e-round.e-danger:focus, .e-btn.e-round-edge.e-danger:focus,
.e-css.e-btn.e-round.e-danger:focus,
.e-css.e-btn.e-round-edge.e-danger:focus {
  outline: #fafafa 0 solid;
}
.e-btn.e-primary,
.e-css.e-btn.e-primary {
  background: #e3165b;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-primary:hover,
.e-css.e-btn.e-primary:hover {
  background: #e6326f;
  border-color: transparent;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.e-btn.e-primary:focus,
.e-css.e-btn.e-primary:focus {
  background: #ea4e82;
  border-color: transparent;
  color: #fff;
  outline: #fafafa 0 solid;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.e-btn.e-primary:active,
.e-css.e-btn.e-primary:active {
  background: #ec618f;
  border-color: transparent;
  color: #fff;
  outline: #fafafa 0 solid;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.e-btn.e-primary.e-active,
.e-css.e-btn.e-primary.e-active {
  background: #ec618f;
  border-color: transparent;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: #fff;
}
.e-btn.e-primary:disabled, .e-btn.e-primary.e-disabled,
.e-css.e-btn.e-primary:disabled,
.e-css.e-btn.e-primary.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-primary .e-ripple-element,
.e-css.e-btn.e-primary .e-ripple-element {
  background: rgba(255, 255, 255, 0.24);
}

.e-btn.e-success,
.e-css.e-btn.e-success {
  background: #4d841d;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-success:hover,
.e-css.e-btn.e-success:hover {
  background: #629338;
  border-color: transparent;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.e-btn.e-success:focus,
.e-css.e-btn.e-success:focus {
  background: #78a253;
  border-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.e-btn.e-success:active, .e-btn.e-success.e-active,
.e-css.e-btn.e-success:active,
.e-css.e-btn.e-success.e-active {
  background: #86ab65;
  border-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.e-btn.e-success:disabled, .e-btn.e-success.e-disabled,
.e-css.e-btn.e-success:disabled,
.e-css.e-btn.e-success.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-success .e-ripple-element,
.e-css.e-btn.e-success .e-ripple-element {
  background: rgba(255, 255, 255, 0.24);
}

.e-btn.e-info,
.e-css.e-btn.e-info {
  background: #0378d5;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-info:hover,
.e-css.e-btn.e-info:hover {
  background: #2188da;
  border-color: transparent;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.e-btn.e-info:focus,
.e-css.e-btn.e-info:focus {
  background: #3f98df;
  border-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.e-btn.e-info:active, .e-btn.e-info.e-active,
.e-css.e-btn.e-info:active,
.e-css.e-btn.e-info.e-active {
  background: #54a3e2;
  color: #fff;
  border-color: transparent;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.e-btn.e-info:disabled, .e-btn.e-info.e-disabled,
.e-css.e-btn.e-info:disabled,
.e-css.e-btn.e-info.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-info .e-ripple-element,
.e-css.e-btn.e-info .e-ripple-element {
  background: rgba(255, 255, 255, 0.24);
}

.e-btn.e-warning,
.e-css.e-btn.e-warning {
  background: #c15700;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-warning:hover,
.e-css.e-btn.e-warning:hover {
  background: #c86b1f;
  border-color: transparent;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.e-btn.e-warning:focus,
.e-css.e-btn.e-warning:focus {
  background: #d07f3d;
  border-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.e-btn.e-warning:active, .e-btn.e-warning.e-active,
.e-css.e-btn.e-warning:active,
.e-css.e-btn.e-warning.e-active {
  background: #d58d52;
  border-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.e-btn.e-warning:disabled, .e-btn.e-warning.e-disabled,
.e-css.e-btn.e-warning:disabled,
.e-css.e-btn.e-warning.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-warning .e-ripple-element,
.e-css.e-btn.e-warning .e-ripple-element {
  background: rgba(255, 255, 255, 0.24);
}

.e-btn.e-danger,
.e-css.e-btn.e-danger {
  background: #d64113;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-danger:hover,
.e-css.e-btn.e-danger:hover {
  background: #db582f;
  border-color: transparent;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.e-btn.e-danger:focus,
.e-css.e-btn.e-danger:focus {
  background: #e06f4c;
  border-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.e-btn.e-danger:active,
.e-css.e-btn.e-danger:active {
  background: #e37e5f;
  border-color: transparent;
  color: #fff;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.e-btn.e-danger.e-active,
.e-css.e-btn.e-danger.e-active {
  background: #e37e5f;
  border-color: transparent;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: #fff;
}
.e-btn.e-danger:disabled, .e-btn.e-danger.e-disabled,
.e-css.e-btn.e-danger:disabled,
.e-css.e-btn.e-danger.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-danger .e-ripple-element,
.e-css.e-btn.e-danger .e-ripple-element {
  background: rgba(255, 255, 255, 0.24);
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.e-btn.e-flat:hover,
.e-css.e-btn.e-flat:hover {
  background: rgba(0, 0, 0, 0.0348);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.e-btn.e-flat:focus,
.e-css.e-btn.e-flat:focus {
  background: rgba(0, 0, 0, 0.1044);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat:active, .e-btn.e-flat.e-active,
.e-css.e-btn.e-flat:active,
.e-css.e-btn.e-flat.e-active {
  background: rgba(0, 0, 0, 0.2088);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat:disabled, .e-btn.e-flat.e-disabled,
.e-css.e-btn.e-flat:disabled,
.e-css.e-btn.e-flat.e-disabled {
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-flat .e-ripple-element,
.e-css.e-btn.e-flat .e-ripple-element {
  background: rgba(0, 0, 0, 0.12);
}
.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background: transparent;
  border-color: transparent;
  color: #e3165b;
}
.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  background: rgba(227, 22, 91, 0.04);
  border-color: transparent;
  color: #e3165b;
}
.e-btn.e-flat.e-primary:focus,
.e-css.e-btn.e-flat.e-primary:focus {
  background: rgba(227, 22, 91, 0.12);
  border-color: transparent;
  color: #e3165b;
}
.e-btn.e-flat.e-primary:active, .e-btn.e-flat.e-primary.e-active,
.e-css.e-btn.e-flat.e-primary:active,
.e-css.e-btn.e-flat.e-primary.e-active {
  background: rgba(227, 22, 91, 0.24);
  border-color: transparent;
  color: #e3165b;
}
.e-btn.e-flat.e-primary:disabled, .e-btn.e-flat.e-primary.e-disabled,
.e-css.e-btn.e-flat.e-primary:disabled,
.e-css.e-btn.e-flat.e-primary.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-flat.e-primary .e-ripple-element,
.e-css.e-btn.e-flat.e-primary .e-ripple-element {
  background: rgba(227, 22, 91, 0.12);
}
.e-btn.e-flat.e-success,
.e-css.e-btn.e-flat.e-success {
  background: transparent;
  border-color: transparent;
  color: #4d841d;
}
.e-btn.e-flat.e-success:hover,
.e-css.e-btn.e-flat.e-success:hover {
  background: rgba(77, 132, 29, 0.04);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #4d841d;
}
.e-btn.e-flat.e-success:focus,
.e-css.e-btn.e-flat.e-success:focus {
  background: rgba(77, 132, 29, 0.12);
  border-color: transparent;
  color: #4d841d;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-success:active, .e-btn.e-flat.e-success.e-active,
.e-css.e-btn.e-flat.e-success:active,
.e-css.e-btn.e-flat.e-success.e-active {
  background: rgba(77, 132, 29, 0.24);
  border-color: transparent;
  color: #4d841d;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-success:disabled, .e-btn.e-flat.e-success.e-disabled,
.e-css.e-btn.e-flat.e-success:disabled,
.e-css.e-btn.e-flat.e-success.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-flat.e-success .e-ripple-element,
.e-css.e-btn.e-flat.e-success .e-ripple-element {
  background: rgba(77, 132, 29, 0.12);
}
.e-btn.e-flat.e-info,
.e-css.e-btn.e-flat.e-info {
  background: transparent;
  border-color: transparent;
  color: #0378d5;
}
.e-btn.e-flat.e-info:hover,
.e-css.e-btn.e-flat.e-info:hover {
  background: rgba(3, 120, 213, 0.04);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #0378d5;
}
.e-btn.e-flat.e-info:focus,
.e-css.e-btn.e-flat.e-info:focus {
  background: rgba(3, 120, 213, 0.12);
  border-color: transparent;
  color: #0378d5;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-info:active, .e-btn.e-flat.e-info.e-active,
.e-css.e-btn.e-flat.e-info:active,
.e-css.e-btn.e-flat.e-info.e-active {
  background: rgba(3, 120, 213, 0.24);
  border-color: transparent;
  color: #0378d5;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-info:disabled, .e-btn.e-flat.e-info.e-disabled,
.e-css.e-btn.e-flat.e-info:disabled,
.e-css.e-btn.e-flat.e-info.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-flat.e-info .e-ripple-element,
.e-css.e-btn.e-flat.e-info .e-ripple-element {
  background: rgba(3, 120, 213, 0.12);
}
.e-btn.e-flat.e-warning,
.e-css.e-btn.e-flat.e-warning {
  background: transparent;
  border-color: transparent;
  color: #c15700;
}
.e-btn.e-flat.e-warning:hover,
.e-css.e-btn.e-flat.e-warning:hover {
  background: rgba(193, 87, 0, 0.04);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #c15700;
}
.e-btn.e-flat.e-warning:focus,
.e-css.e-btn.e-flat.e-warning:focus {
  background: rgba(193, 87, 0, 0.12);
  border-color: transparent;
  color: #c15700;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-warning:active, .e-btn.e-flat.e-warning.e-active,
.e-css.e-btn.e-flat.e-warning:active,
.e-css.e-btn.e-flat.e-warning.e-active {
  background: rgba(193, 87, 0, 0.24);
  border-color: transparent;
  color: #c15700;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-warning:disabled, .e-btn.e-flat.e-warning.e-disabled,
.e-css.e-btn.e-flat.e-warning:disabled,
.e-css.e-btn.e-flat.e-warning.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-flat.e-warning .e-ripple-element,
.e-css.e-btn.e-flat.e-warning .e-ripple-element {
  background: rgba(193, 87, 0, 0.12);
}
.e-btn.e-flat.e-danger,
.e-css.e-btn.e-flat.e-danger {
  background: transparent;
  border-color: transparent;
  color: #d64113;
}
.e-btn.e-flat.e-danger:hover,
.e-css.e-btn.e-flat.e-danger:hover {
  background: rgba(214, 65, 19, 0.04);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #d64113;
}
.e-btn.e-flat.e-danger:focus,
.e-css.e-btn.e-flat.e-danger:focus {
  background: rgba(214, 65, 19, 0.12);
  border-color: transparent;
  color: #d64113;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-danger:active, .e-btn.e-flat.e-danger.e-active,
.e-css.e-btn.e-flat.e-danger:active,
.e-css.e-btn.e-flat.e-danger.e-active {
  background: rgba(214, 65, 19, 0.24);
  border-color: transparent;
  color: #d64113;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-flat.e-danger:disabled, .e-btn.e-flat.e-danger.e-disabled,
.e-css.e-btn.e-flat.e-danger:disabled,
.e-css.e-btn.e-flat.e-danger.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-flat.e-danger .e-ripple-element,
.e-css.e-btn.e-flat.e-danger .e-ripple-element {
  background: rgba(214, 65, 19, 0.12);
}

.e-btn.e-outline,
.e-css.e-btn.e-outline {
  background: transparent;
  border-color: #adadad;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.e-btn.e-outline:hover,
.e-css.e-btn.e-outline:hover {
  background: rgba(0, 0, 0, 0.12);
  border-color: #adadad;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.e-btn.e-outline:focus,
.e-css.e-btn.e-outline:focus {
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.e-btn.e-outline:active, .e-btn.e-outline.e-active,
.e-css.e-btn.e-outline:active,
.e-css.e-btn.e-outline.e-active {
  background: rgba(0, 0, 0, 0.32);
  border-color: #adadad;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.e-btn.e-outline:disabled,
.e-css.e-btn.e-outline:disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-outline.e-primary,
.e-css.e-btn.e-outline.e-primary {
  background: transparent;
  border-color: #e3165b;
  color: #e3165b;
}
.e-btn.e-outline.e-primary:hover,
.e-css.e-btn.e-outline.e-primary:hover {
  background: #e6326f;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-outline.e-primary:active, .e-btn.e-outline.e-primary.e-active,
.e-css.e-btn.e-outline.e-primary:active,
.e-css.e-btn.e-outline.e-primary.e-active {
  background: #ec618f;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
}
.e-btn.e-outline.e-primary:disabled, .e-btn.e-outline.e-primary.e-disabled,
.e-css.e-btn.e-outline.e-primary:disabled,
.e-css.e-btn.e-outline.e-primary.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-outline.e-success,
.e-css.e-btn.e-outline.e-success {
  background: transparent;
  border-color: #4d841d;
  color: #4d841d;
}
.e-btn.e-outline.e-success:hover,
.e-css.e-btn.e-outline.e-success:hover {
  background: #629338;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-outline.e-success:active, .e-btn.e-outline.e-success.e-active,
.e-css.e-btn.e-outline.e-success:active,
.e-css.e-btn.e-outline.e-success.e-active {
  background: #86ab65;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
}
.e-btn.e-outline.e-success:disabled, .e-btn.e-outline.e-success.e-disabled,
.e-css.e-btn.e-outline.e-success:disabled,
.e-css.e-btn.e-outline.e-success.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-outline.e-info,
.e-css.e-btn.e-outline.e-info {
  background: transparent;
  border-color: #0378d5;
  color: #0378d5;
}
.e-btn.e-outline.e-info:hover,
.e-css.e-btn.e-outline.e-info:hover {
  background: #2188da;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-outline.e-info:active, .e-btn.e-outline.e-info.e-active,
.e-css.e-btn.e-outline.e-info:active,
.e-css.e-btn.e-outline.e-info.e-active {
  background: #54a3e2;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
}
.e-btn.e-outline.e-info:disabled, .e-btn.e-outline.e-info.e-disabled,
.e-css.e-btn.e-outline.e-info:disabled,
.e-css.e-btn.e-outline.e-info.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-outline.e-warning,
.e-css.e-btn.e-outline.e-warning {
  background: transparent;
  border-color: #c15700;
  color: #c15700;
}
.e-btn.e-outline.e-warning:hover,
.e-css.e-btn.e-outline.e-warning:hover {
  background: #c86b1f;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-outline.e-warning:active, .e-btn.e-outline.e-warning.e-active,
.e-css.e-btn.e-outline.e-warning:active,
.e-css.e-btn.e-outline.e-warning.e-active {
  background: #d58d52;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
}
.e-btn.e-outline.e-warning:disabled, .e-btn.e-outline.e-warning.e-disabled,
.e-css.e-btn.e-outline.e-warning:disabled,
.e-css.e-btn.e-outline.e-warning.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}
.e-btn.e-outline.e-danger,
.e-css.e-btn.e-outline.e-danger {
  background: transparent;
  border-color: #d64113;
  color: #d64113;
}
.e-btn.e-outline.e-danger:hover,
.e-css.e-btn.e-outline.e-danger:hover {
  background: #db582f;
  border-color: transparent;
  color: #fff;
}
.e-btn.e-outline.e-danger:active, .e-btn.e-outline.e-danger.e-active,
.e-css.e-btn.e-outline.e-danger:active,
.e-css.e-btn.e-outline.e-danger.e-active {
  background: #e37e5f;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
}
.e-btn.e-outline.e-danger:disabled, .e-btn.e-outline.e-danger.e-disabled,
.e-css.e-btn.e-outline.e-danger:disabled,
.e-css.e-btn.e-outline.e-danger.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
}

.e-btn.e-link,
.e-css.e-btn.e-link {
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #0d47a1;
}
.e-btn.e-link:hover,
.e-css.e-btn.e-link:hover {
  border-radius: 0;
  color: #0a3576;
  text-decoration: underline;
}
.e-btn.e-link:focus,
.e-css.e-btn.e-link:focus {
  border-radius: 0;
  text-decoration: underline;
  color: #0a3576;
}
.e-btn.e-link:focus:not(:focus-visible),
.e-css.e-btn.e-link:focus:not(:focus-visible) {
  outline: none !important; /* stylelint-disable-line declaration-no-important */
}
.e-btn.e-link:disabled,
.e-css.e-btn.e-link:disabled {
  color: rgba(0, 0, 0, 0.26);
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-decoration: none;
}

.e-btn.e-inherit,
.e-css.e-btn.e-inherit {
  color: inherit;
  background: inherit;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn.e-inherit:hover, .e-btn.e-inherit:focus, .e-btn.e-inherit:active, .e-btn.e-inherit.e-active,
.e-css.e-btn.e-inherit:hover,
.e-css.e-btn.e-inherit:focus,
.e-css.e-btn.e-inherit:active,
.e-css.e-btn.e-inherit.e-active {
  background: rgba(0, 0, 0, 0.056);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: inherit;
  outline: none;
}
.e-btn.e-inherit:disabled,
.e-css.e-btn.e-inherit:disabled {
  background: inherit;
  color: inherit;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  opacity: 0.5;
}

.e-checkbox-wrapper .e-check::before,
.e-css.e-checkbox-wrapper .e-check::before {
  content: "\e933";
}

.e-checkbox-wrapper .e-stop::before,
.e-css.e-checkbox-wrapper .e-stop::before {
  content: "\e934";
}

.e-checkbox-wrapper,
.e-css.e-checkbox-wrapper {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.e-checkbox-wrapper label,
.e-css.e-checkbox-wrapper label {
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  margin: 0;
  position: relative;
  white-space: nowrap;
}

.e-checkbox-wrapper:focus .e-frame, .e-checkbox-wrapper.e-focus .e-frame,
.e-css.e-checkbox-wrapper:focus .e-frame,
.e-css.e-checkbox-wrapper.e-focus .e-frame {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.e-checkbox-wrapper .e-ripple-container,
.e-css.e-checkbox-wrapper .e-ripple-container {
  border-radius: 50%;
  bottom: -9px;
  height: 36px;
  left: -9px;
  pointer-events: none;
  position: absolute;
  right: -9px;
  top: -9px;
  width: 36px;
  z-index: 1;
}
.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label {
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: normal;
}

.e-checkbox-wrapper .e-checkbox,
.e-css.e-checkbox-wrapper .e-checkbox {
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
.e-checkbox-wrapper .e-checkbox + .e-label,
.e-css.e-checkbox-wrapper .e-checkbox + .e-label {
  margin-right: 10px;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
  border: 2px solid;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "e-icons";
  height: 18px;
  line-height: 10px;
  padding: 2px 0;
  text-align: center;
  vertical-align: middle;
  width: 18px;
}
.e-checkbox-wrapper .e-frame + .e-label,
.e-css.e-checkbox-wrapper .e-frame + .e-label {
  margin-left: 10px;
}
.e-checkbox-wrapper .e-frame + .e-ripple-container,
.e-css.e-checkbox-wrapper .e-frame + .e-ripple-container {
  left: auto;
}

.e-checkbox-wrapper .e-check,
.e-css.e-checkbox-wrapper .e-check {
  font-size: 12px;
}

.e-checkbox-wrapper .e-stop,
.e-css.e-checkbox-wrapper .e-stop {
  font-size: 10px;
  line-height: 10px;
}

.e-checkbox-wrapper.e-checkbox-disabled,
.e-css.e-checkbox-wrapper.e-checkbox-disabled {
  cursor: default;
  pointer-events: none;
}
.e-checkbox-wrapper.e-checkbox-disabled .e-frame,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
  cursor: default;
}
.e-checkbox-wrapper.e-checkbox-disabled .e-label,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-label {
  cursor: default;
}

.e-checkbox-wrapper.e-rtl .e-ripple-container,
.e-css.e-checkbox-wrapper.e-rtl .e-ripple-container {
  right: -9px;
}
.e-checkbox-wrapper.e-rtl .e-frame,
.e-css.e-checkbox-wrapper.e-rtl .e-frame {
  margin: 0;
}
.e-checkbox-wrapper.e-rtl .e-frame:hover,
.e-css.e-checkbox-wrapper.e-rtl .e-frame:hover {
  background-color: #fff;
  border-color: #757575;
}
.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container,
.e-css.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container {
  left: -9px;
  right: auto;
}
.e-checkbox-wrapper.e-rtl .e-label,
.e-css.e-checkbox-wrapper.e-rtl .e-label {
  margin-left: 0;
  margin-right: 10px;
}
.e-checkbox-wrapper.e-rtl .e-label + .e-frame,
.e-css.e-checkbox-wrapper.e-rtl .e-label + .e-frame {
  margin: 0;
}
.e-checkbox-wrapper.e-rtl .e-checkbox + .e-label,
.e-css.e-checkbox-wrapper.e-rtl .e-checkbox + .e-label {
  margin-left: 10px;
  margin-right: 0;
}

.e-checkbox-wrapper.e-small .e-frame,
.e-css.e-checkbox-wrapper.e-small .e-frame {
  height: 14px;
  line-height: 6px;
  width: 14px;
}
.e-checkbox-wrapper.e-small .e-check,
.e-css.e-checkbox-wrapper.e-small .e-check {
  font-size: 10px;
}
.e-checkbox-wrapper.e-small .e-stop,
.e-css.e-checkbox-wrapper.e-small .e-stop {
  font-size: 8px;
  line-height: 6px;
}
.e-checkbox-wrapper.e-small .e-label,
.e-css.e-checkbox-wrapper.e-small .e-label {
  font-size: 13px;
  line-height: 14px;
}
.e-checkbox-wrapper.e-small .e-ripple-container,
.e-css.e-checkbox-wrapper.e-small .e-ripple-container {
  bottom: -9px;
  height: 32px;
  left: -9px;
  right: -9px;
  top: -9px;
  width: 32px;
}

.e-checkbox-wrapper[readonly] {
  pointer-events: none;
}

.e-small .e-checkbox-wrapper .e-frame,
.e-small.e-checkbox-wrapper .e-frame,
.e-small .e-css.e-checkbox-wrapper .e-frame,
.e-small.e-css.e-checkbox-wrapper .e-frame {
  height: 14px;
  line-height: 6px;
  width: 14px;
}
.e-small .e-checkbox-wrapper .e-frame:hover,
.e-small.e-checkbox-wrapper .e-frame:hover,
.e-small .e-css.e-checkbox-wrapper .e-frame:hover,
.e-small.e-css.e-checkbox-wrapper .e-frame:hover {
  background-color: #fff;
  border-color: #757575;
}
.e-small .e-checkbox-wrapper .e-check,
.e-small.e-checkbox-wrapper .e-check,
.e-small .e-css.e-checkbox-wrapper .e-check,
.e-small.e-css.e-checkbox-wrapper .e-check {
  font-size: 10px;
}
.e-small .e-checkbox-wrapper .e-stop,
.e-small.e-checkbox-wrapper .e-stop,
.e-small .e-css.e-checkbox-wrapper .e-stop,
.e-small.e-css.e-checkbox-wrapper .e-stop {
  font-size: 8px;
  line-height: 6px;
}
.e-small .e-checkbox-wrapper .e-label,
.e-small.e-checkbox-wrapper .e-label,
.e-small .e-css.e-checkbox-wrapper .e-label,
.e-small.e-css.e-checkbox-wrapper .e-label {
  font-size: 13px;
  line-height: 14px;
}
.e-small .e-checkbox-wrapper .e-ripple-container,
.e-small.e-checkbox-wrapper .e-ripple-container,
.e-small .e-css.e-checkbox-wrapper .e-ripple-container,
.e-small.e-css.e-checkbox-wrapper .e-ripple-container {
  bottom: -9px;
  height: 32px;
  left: -9px;
  right: -9px;
  top: -9px;
  width: 32px;
}

.e-bigger.e-small .e-checkbox-wrapper .e-frame,
.e-bigger.e-small.e-checkbox-wrapper .e-frame,
.e-bigger.e-small .e-css.e-checkbox-wrapper .e-frame,
.e-bigger.e-small.e-css.e-checkbox-wrapper .e-frame {
  height: 20px;
  line-height: 12px;
  width: 20px;
}
.e-bigger.e-small .e-checkbox-wrapper .e-frame:hover.e-check,
.e-bigger.e-small.e-checkbox-wrapper .e-frame:hover.e-check,
.e-bigger.e-small .e-css.e-checkbox-wrapper .e-frame:hover.e-check,
.e-bigger.e-small.e-css.e-checkbox-wrapper .e-frame:hover.e-check {
  background-color: #e3165b;
  border-color: transparent;
}
.e-bigger.e-small .e-checkbox-wrapper .e-check,
.e-bigger.e-small.e-checkbox-wrapper .e-check,
.e-bigger.e-small .e-css.e-checkbox-wrapper .e-check,
.e-bigger.e-small.e-css.e-checkbox-wrapper .e-check {
  font-size: 12px;
}
.e-bigger.e-small .e-checkbox-wrapper .e-stop,
.e-bigger.e-small.e-checkbox-wrapper .e-stop,
.e-bigger.e-small .e-css.e-checkbox-wrapper .e-stop,
.e-bigger.e-small.e-css.e-checkbox-wrapper .e-stop {
  font-size: 10px;
  line-height: 12px;
}
.e-bigger.e-small .e-checkbox-wrapper .e-label,
.e-bigger.e-small.e-checkbox-wrapper .e-label,
.e-bigger.e-small .e-css.e-checkbox-wrapper .e-label,
.e-bigger.e-small.e-css.e-checkbox-wrapper .e-label {
  font-size: 14px;
  line-height: 20px;
}
.e-bigger.e-small .e-checkbox-wrapper .e-ripple-container,
.e-bigger.e-small.e-checkbox-wrapper .e-ripple-container,
.e-bigger.e-small .e-css.e-checkbox-wrapper .e-ripple-container,
.e-bigger.e-small.e-css.e-checkbox-wrapper .e-ripple-container {
  bottom: -9px;
  height: 38px;
  left: -9px;
  right: -9px;
  top: -9px;
  width: 38px;
}

.e-bigger .e-checkbox-wrapper .e-frame,
.e-bigger.e-checkbox-wrapper .e-frame,
.e-bigger .e-css.e-checkbox-wrapper .e-frame,
.e-bigger.e-css.e-checkbox-wrapper .e-frame {
  height: 22px;
  line-height: 14px;
  width: 22px;
}
.e-bigger .e-checkbox-wrapper .e-frame:hover,
.e-bigger.e-checkbox-wrapper .e-frame:hover,
.e-bigger .e-css.e-checkbox-wrapper .e-frame:hover,
.e-bigger.e-css.e-checkbox-wrapper .e-frame:hover {
  background-color: #fff;
  border-color: #757575;
}
.e-bigger .e-checkbox-wrapper .e-frame + .e-label,
.e-bigger.e-checkbox-wrapper .e-frame + .e-label,
.e-bigger .e-css.e-checkbox-wrapper .e-frame + .e-label,
.e-bigger.e-css.e-checkbox-wrapper .e-frame + .e-label {
  font-size: 14px;
  line-height: 22px;
  margin-left: 12px;
}
.e-bigger .e-checkbox-wrapper .e-frame + .e-ripple-container,
.e-bigger.e-checkbox-wrapper .e-frame + .e-ripple-container,
.e-bigger .e-css.e-checkbox-wrapper .e-frame + .e-ripple-container,
.e-bigger.e-css.e-checkbox-wrapper .e-frame + .e-ripple-container {
  left: auto;
}
.e-bigger .e-checkbox-wrapper .e-check,
.e-bigger.e-checkbox-wrapper .e-check,
.e-bigger .e-css.e-checkbox-wrapper .e-check,
.e-bigger.e-css.e-checkbox-wrapper .e-check {
  font-size: 16px;
}
.e-bigger .e-checkbox-wrapper .e-stop,
.e-bigger.e-checkbox-wrapper .e-stop,
.e-bigger .e-css.e-checkbox-wrapper .e-stop,
.e-bigger.e-css.e-checkbox-wrapper .e-stop {
  font-size: 12px;
  line-height: 14px;
}
.e-bigger .e-checkbox-wrapper .e-label,
.e-bigger.e-checkbox-wrapper .e-label,
.e-bigger .e-css.e-checkbox-wrapper .e-label,
.e-bigger.e-css.e-checkbox-wrapper .e-label {
  font-size: 14px;
}
.e-bigger .e-checkbox-wrapper .e-ripple-container,
.e-bigger.e-checkbox-wrapper .e-ripple-container,
.e-bigger .e-css.e-checkbox-wrapper .e-ripple-container,
.e-bigger.e-css.e-checkbox-wrapper .e-ripple-container {
  bottom: -9px;
  height: 40px;
  left: -9px;
  right: -9px;
  top: -9px;
  width: 40px;
}
.e-bigger .e-checkbox-wrapper.e-rtl .e-frame,
.e-bigger.e-checkbox-wrapper.e-rtl .e-frame,
.e-bigger .e-css.e-checkbox-wrapper.e-rtl .e-frame,
.e-bigger.e-css.e-checkbox-wrapper.e-rtl .e-frame {
  margin: 0;
}
.e-bigger .e-checkbox-wrapper.e-rtl .e-frame:hover.e-check,
.e-bigger.e-checkbox-wrapper.e-rtl .e-frame:hover.e-check,
.e-bigger .e-css.e-checkbox-wrapper.e-rtl .e-frame:hover.e-check,
.e-bigger.e-css.e-checkbox-wrapper.e-rtl .e-frame:hover.e-check {
  background-color: #e3165b;
  border-color: transparent;
}
.e-bigger .e-checkbox-wrapper.e-rtl .e-frame + .e-label,
.e-bigger.e-checkbox-wrapper.e-rtl .e-frame + .e-label,
.e-bigger .e-css.e-checkbox-wrapper.e-rtl .e-frame + .e-label,
.e-bigger.e-css.e-checkbox-wrapper.e-rtl .e-frame + .e-label {
  margin-left: 0;
  margin-right: 12px;
}
.e-bigger .e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container,
.e-bigger.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container,
.e-bigger .e-css.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container,
.e-bigger.e-css.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container {
  left: auto;
}
.e-bigger .e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container,
.e-bigger.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container,
.e-bigger .e-css.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container,
.e-bigger.e-css.e-checkbox-wrapper.e-rtl .e-frame + .e-ripple-container {
  right: auto;
}
.e-bigger .e-checkbox-wrapper.e-small .e-frame,
.e-bigger.e-checkbox-wrapper.e-small .e-frame,
.e-bigger .e-css.e-checkbox-wrapper.e-small .e-frame,
.e-bigger.e-css.e-checkbox-wrapper.e-small .e-frame {
  height: 20px;
  line-height: 12px;
  width: 20px;
}
.e-bigger .e-checkbox-wrapper.e-small .e-check,
.e-bigger.e-checkbox-wrapper.e-small .e-check,
.e-bigger .e-css.e-checkbox-wrapper.e-small .e-check,
.e-bigger.e-css.e-checkbox-wrapper.e-small .e-check {
  font-size: 12px;
}
.e-bigger .e-checkbox-wrapper.e-small .e-stop,
.e-bigger.e-checkbox-wrapper.e-small .e-stop,
.e-bigger .e-css.e-checkbox-wrapper.e-small .e-stop,
.e-bigger.e-css.e-checkbox-wrapper.e-small .e-stop {
  font-size: 10px;
  line-height: 12px;
}
.e-bigger .e-checkbox-wrapper.e-small .e-label,
.e-bigger.e-checkbox-wrapper.e-small .e-label,
.e-bigger .e-css.e-checkbox-wrapper.e-small .e-label,
.e-bigger.e-css.e-checkbox-wrapper.e-small .e-label {
  font-size: 14px;
  line-height: 20px;
}
.e-bigger .e-checkbox-wrapper.e-small .e-ripple-container,
.e-bigger.e-checkbox-wrapper.e-small .e-ripple-container,
.e-bigger .e-css.e-checkbox-wrapper.e-small .e-ripple-container,
.e-bigger.e-css.e-checkbox-wrapper.e-small .e-ripple-container {
  bottom: -9px;
  height: 38px;
  left: -9px;
  right: -9px;
  top: -9px;
  width: 38px;
}

.e-checkbox-wrapper,
.e-css.e-checkbox-wrapper {
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-tap-highlight-color: transparent;
}
.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
  background-color: #fff;
  border-color: #757575;
}
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #e3165b;
  border-color: transparent;
  color: #fff;
}
.e-checkbox-wrapper .e-frame.e-stop,
.e-css.e-checkbox-wrapper .e-frame.e-stop {
  background-color: #fff;
  border-color: #757575;
  color: #757575;
}

.e-checkbox-wrapper .e-ripple-element,
.e-css.e-checkbox-wrapper .e-ripple-element {
  background: rgba(227, 22, 91, 0.26);
}

.e-checkbox-wrapper .e-ripple-check .e-ripple-element,
.e-css.e-checkbox-wrapper .e-ripple-check .e-ripple-element {
  background: rgba(0, 0, 0, 0.26);
}

.e-checkbox-wrapper:active .e-ripple-element,
.e-css.e-checkbox-wrapper:active .e-ripple-element {
  background: rgba(0, 0, 0, 0.26);
}
.e-checkbox-wrapper:active .e-ripple-check .e-ripple-element,
.e-css.e-checkbox-wrapper:active .e-ripple-check .e-ripple-element {
  background: rgba(227, 22, 91, 0.26);
}

.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-checkbox-wrapper .e-checkbox:focus + .e-frame, .e-checkbox-wrapper .e-checkbox:active + .e-frame,
.e-css.e-checkbox-wrapper .e-checkbox:focus + .e-frame,
.e-css.e-checkbox-wrapper .e-checkbox:active + .e-frame {
  background-color: #fff;
  border-color: #757575;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check, .e-checkbox-wrapper .e-checkbox:active + .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-checkbox:active + .e-frame.e-check {
  background-color: #e3165b;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
}
.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-stop, .e-checkbox-wrapper .e-checkbox:active + .e-frame.e-stop,
.e-css.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-stop,
.e-css.e-checkbox-wrapper .e-checkbox:active + .e-frame.e-stop {
  background-color: #e3165b;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #757575;
}

.e-checkbox-wrapper:hover .e-frame,
.e-css.e-checkbox-wrapper:hover .e-frame {
  background-color: #fff;
  border-color: #757575;
}
.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: #e3165b;
  border-color: transparent;
  color: #fff;
}
.e-checkbox-wrapper:hover .e-frame.e-stop,
.e-css.e-checkbox-wrapper:hover .e-frame.e-stop {
  color: #757575;
}
.e-checkbox-wrapper:hover .e-label,
.e-css.e-checkbox-wrapper:hover .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
  background-color: #fff;
  border-color: #bdbdbd;
  color: #bdbdbd;
}
.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
  color: #fff;
}
.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-stop,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-stop {
  background-color: #fff;
  border-color: #bdbdbd;
  color: #bdbdbd;
}
.e-checkbox-wrapper.e-checkbox-disabled .e-label,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-label {
  color: #bdbdbd;
}

.e-checkbox-wrapper.e-focus .e-ripple-container,
.e-css.e-checkbox-wrapper.e-focus .e-ripple-container {
  background-color: rgba(0, 0, 0, 0.12);
}
.e-checkbox-wrapper.e-focus .e-ripple-container.e-ripple-check,
.e-css.e-checkbox-wrapper.e-focus .e-ripple-container.e-ripple-check {
  background-color: rgba(227, 22, 91, 0.26);
}
.e-checkbox-wrapper.e-focus .e-frame, .e-checkbox-wrapper.e-focus .e-frame.e-check,
.e-css.e-checkbox-wrapper.e-focus .e-frame,
.e-css.e-checkbox-wrapper.e-focus .e-frame.e-check {
  outline: #fff 0 solid;
  outline-offset: 0;
}

.e-radio-wrapper {
  display: inline-block;
  line-height: 1;
  position: relative;
}

/* stylelint-disable property-no-vendor-prefix */
.e-radio {
  -webkit-appearance: none;
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
.e-radio:not(:checked):not(:disabled):hover + label.e-rtl::after,
.e-radio:not(:checked):not(:disabled):hover + label.e-right::after {
  left: auto;
}
.e-radio + label {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.e-radio + label .e-label {
  display: inline-block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  padding-left: 28px;
  vertical-align: text-top;
  white-space: normal;
}
.e-radio + label::before {
  border: 2px solid;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  content: "";
  height: 18px;
  left: 0;
  position: absolute;
  width: 18px;
}
.e-radio + label:focus::before {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-radio + label::after {
  border: 1px solid;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  content: "";
  height: 8px;
  left: 5px;
  position: absolute;
  top: 5px;
  -webkit-transform: scale(0);
          transform: scale(0);
  width: 8px;
}
.e-radio + label .e-ripple-container {
  border-radius: 50%;
  height: 34px;
  left: -8px;
  position: absolute;
  top: -8px;
  width: 34px;
  z-index: 1;
}
.e-radio + label.e-right .e-label, .e-radio + label.e-rtl .e-label {
  padding-left: 0;
  padding-right: 28px;
}
.e-radio + label.e-right::before, .e-radio + label.e-rtl::before {
  left: auto;
  right: 0;
}
.e-radio + label.e-right::after, .e-radio + label.e-rtl::after {
  left: auto;
  right: 5px;
}
.e-radio + label.e-right .e-ripple-container, .e-radio + label.e-rtl .e-ripple-container {
  left: auto;
  right: -8px;
}
.e-radio + label.e-right.e-rtl .e-label {
  padding-left: 28px;
  padding-right: 0;
}
.e-radio + label.e-right.e-rtl::before {
  left: 0;
  right: auto;
}
.e-radio + label.e-right.e-rtl::after {
  left: 5px;
  right: auto;
}
.e-radio + label.e-right.e-rtl .e-ripple-container {
  left: -8px;
  right: auto;
}
.e-radio + label.e-small .e-label {
  line-height: 14px;
  padding-left: 24px;
}
.e-radio + label.e-small::before {
  height: 14px;
  width: 14px;
}
.e-radio + label.e-small::after {
  height: 6px;
  left: 4px;
  top: 4px;
  width: 6px;
}
.e-radio + label.e-small .e-ripple-container {
  left: -10px;
  top: -10px;
}
.e-radio + label.e-small.e-right .e-label, .e-radio + label.e-small.e-rtl .e-label {
  padding-left: 0;
  padding-right: 24px;
}
.e-radio + label.e-small.e-right::after, .e-radio + label.e-small.e-rtl::after {
  left: auto;
  right: 4px;
}
.e-radio + label.e-small.e-right .e-ripple-container, .e-radio + label.e-small.e-rtl .e-ripple-container {
  left: auto;
  right: -10px;
}
.e-radio + label.e-small.e-right.e-rtl .e-label {
  padding-left: 24px;
  padding-right: 0;
}
.e-radio + label.e-small.e-right.e-rtl::after {
  left: 4px;
  right: auto;
}
.e-radio + label.e-small.e-right.e-rtl .e-ripple-container {
  left: -10px;
  right: auto;
}
.e-radio:checked + label::after {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: background-color ease 280ms, -webkit-transform ease 280ms;
  transition: background-color ease 280ms, -webkit-transform ease 280ms;
  transition: transform ease 280ms, background-color ease 280ms;
  transition: transform ease 280ms, background-color ease 280ms, -webkit-transform ease 280ms;
}

.e-small .e-radio + label .e-label,
.e-radio + label.e-small .e-label {
  line-height: 14px;
  padding-left: 24px;
}
.e-small .e-radio + label::before,
.e-radio + label.e-small::before {
  height: 14px;
  width: 14px;
}
.e-small .e-radio + label::after,
.e-radio + label.e-small::after {
  height: 6px;
  left: 4px;
  top: 4px;
  width: 6px;
}
.e-small .e-radio + label .e-ripple-container,
.e-radio + label.e-small .e-ripple-container {
  left: -10px;
  top: -10px;
}
.e-small .e-radio + label.e-right .e-label, .e-small .e-radio + label.e-rtl .e-label,
.e-radio + label.e-small.e-right .e-label,
.e-radio + label.e-small.e-rtl .e-label {
  padding-left: 0;
  padding-right: 24px;
}
.e-small .e-radio + label.e-right::after, .e-small .e-radio + label.e-rtl::after,
.e-radio + label.e-small.e-right::after,
.e-radio + label.e-small.e-rtl::after {
  left: auto;
  right: 4px;
}
.e-small .e-radio + label.e-right .e-ripple-container, .e-small .e-radio + label.e-rtl .e-ripple-container,
.e-radio + label.e-small.e-right .e-ripple-container,
.e-radio + label.e-small.e-rtl .e-ripple-container {
  left: auto;
  right: -10px;
}
.e-small .e-radio + label.e-right.e-rtl .e-label,
.e-radio + label.e-small.e-right.e-rtl .e-label {
  padding-left: 24px;
  padding-right: 0;
}
.e-small .e-radio + label.e-right.e-rtl::after,
.e-radio + label.e-small.e-right.e-rtl::after {
  left: 4px;
  right: auto;
}
.e-small .e-radio + label.e-right.e-rtl .e-ripple-container,
.e-radio + label.e-small.e-right.e-rtl .e-ripple-container {
  left: -10px;
  right: auto;
}

.e-bigger.e-small .e-radio + label .e-label,
.e-radio + label.e-bigger.e-small .e-label {
  line-height: 20px;
  padding-left: 32px;
}
.e-bigger.e-small .e-radio + label::before,
.e-radio + label.e-bigger.e-small::before {
  height: 20px;
  width: 20px;
}
.e-bigger.e-small .e-radio + label::after,
.e-radio + label.e-bigger.e-small::after {
  height: 8px;
  left: 6px;
  top: 6px;
  width: 8px;
}
.e-bigger.e-small .e-radio + label .e-ripple-container,
.e-radio + label.e-bigger.e-small .e-ripple-container {
  height: 40px;
  left: -10px;
  top: -10px;
  width: 40px;
}
.e-bigger.e-small .e-radio + label.e-right .e-label, .e-bigger.e-small .e-radio + label.e-rtl .e-label,
.e-radio + label.e-bigger.e-small.e-right .e-label,
.e-radio + label.e-bigger.e-small.e-rtl .e-label {
  padding-left: 0;
  padding-right: 32px;
}
.e-bigger.e-small .e-radio + label.e-right::after, .e-bigger.e-small .e-radio + label.e-rtl::after,
.e-radio + label.e-bigger.e-small.e-right::after,
.e-radio + label.e-bigger.e-small.e-rtl::after {
  left: auto;
  right: 6px;
}
.e-bigger.e-small .e-radio + label.e-right .e-ripple-container, .e-bigger.e-small .e-radio + label.e-rtl .e-ripple-container,
.e-radio + label.e-bigger.e-small.e-right .e-ripple-container,
.e-radio + label.e-bigger.e-small.e-rtl .e-ripple-container {
  left: auto;
  right: -10px;
}
.e-bigger.e-small .e-radio + label.e-right.e-rtl .e-label,
.e-radio + label.e-bigger.e-small.e-right.e-rtl .e-label {
  padding-left: 32px;
  padding-right: 0;
}
.e-bigger.e-small .e-radio + label.e-right.e-rtl::after,
.e-radio + label.e-bigger.e-small.e-right.e-rtl::after {
  left: 6px;
  right: auto;
}
.e-bigger.e-small .e-radio + label.e-right.e-rtl .e-ripple-container,
.e-radio + label.e-bigger.e-small.e-right.e-rtl .e-ripple-container {
  left: -10px;
  right: auto;
}

.e-bigger .e-radio:not(:checked):hover + label.e-rtl::after,
.e-bigger .e-radio:not(:checked):hover + label.e-right::after {
  left: auto;
}
.e-bigger .e-radio + label .e-label,
.e-radio + label.e-bigger .e-label {
  font-size: 14px;
  line-height: 22px;
  padding-left: 34px;
}
.e-bigger .e-radio + label::before,
.e-radio + label.e-bigger::before {
  height: 22px;
  width: 22px;
}
.e-bigger .e-radio + label::after,
.e-radio + label.e-bigger::after {
  height: 10px;
  left: 6px;
  top: 6px;
  width: 10px;
}
.e-bigger .e-radio + label .e-ripple-container,
.e-radio + label.e-bigger .e-ripple-container {
  height: 42px;
  left: -10px;
  top: -10px;
  width: 42px;
}
.e-bigger .e-radio + label.e-right .e-label, .e-bigger .e-radio + label.e-rtl .e-label,
.e-radio + label.e-bigger.e-right .e-label,
.e-radio + label.e-bigger.e-rtl .e-label {
  padding-left: 0;
  padding-right: 34px;
}
.e-bigger .e-radio + label.e-right::after, .e-bigger .e-radio + label.e-rtl::after,
.e-radio + label.e-bigger.e-right::after,
.e-radio + label.e-bigger.e-rtl::after {
  left: auto;
  right: 6px;
}
.e-bigger .e-radio + label.e-right .e-ripple-container, .e-bigger .e-radio + label.e-rtl .e-ripple-container,
.e-radio + label.e-bigger.e-right .e-ripple-container,
.e-radio + label.e-bigger.e-rtl .e-ripple-container {
  left: auto;
  right: -10px;
}
.e-bigger .e-radio + label.e-right.e-rtl .e-label,
.e-radio + label.e-bigger.e-right.e-rtl .e-label {
  padding-left: 34px;
  padding-right: 0;
}
.e-bigger .e-radio + label.e-right.e-rtl::after,
.e-radio + label.e-bigger.e-right.e-rtl::after {
  left: 6px;
  right: auto;
}
.e-bigger .e-radio + label.e-right.e-rtl .e-ripple-container,
.e-radio + label.e-bigger.e-right.e-rtl .e-ripple-container {
  left: -12px;
  right: auto;
}
.e-bigger .e-radio + label.e-small .e-label,
.e-radio + label.e-bigger.e-small .e-label {
  line-height: 20px;
  padding-left: 32px;
}
.e-bigger .e-radio + label.e-small::before,
.e-radio + label.e-bigger.e-small::before {
  height: 20px;
  width: 20px;
}
.e-bigger .e-radio + label.e-small::after,
.e-radio + label.e-bigger.e-small::after {
  height: 8px;
  left: 6px;
  top: 6px;
  width: 8px;
}
.e-bigger .e-radio + label.e-small .e-ripple-container,
.e-radio + label.e-bigger.e-small .e-ripple-container {
  height: 40px;
  left: -10px;
  top: -10px;
  width: 40px;
}
.e-bigger .e-radio + label.e-small.e-right .e-label, .e-bigger .e-radio + label.e-small.e-rtl .e-label,
.e-radio + label.e-bigger.e-small.e-right .e-label,
.e-radio + label.e-bigger.e-small.e-rtl .e-label {
  padding-left: 0;
  padding-right: 32px;
}
.e-bigger .e-radio + label.e-small.e-right::after, .e-bigger .e-radio + label.e-small.e-rtl::after,
.e-radio + label.e-bigger.e-small.e-right::after,
.e-radio + label.e-bigger.e-small.e-rtl::after {
  left: auto;
  right: 6px;
}
.e-bigger .e-radio + label.e-small.e-right .e-ripple-container, .e-bigger .e-radio + label.e-small.e-rtl .e-ripple-container,
.e-radio + label.e-bigger.e-small.e-right .e-ripple-container,
.e-radio + label.e-bigger.e-small.e-rtl .e-ripple-container {
  left: auto;
  right: -10px;
}
.e-bigger .e-radio + label.e-small.e-right.e-rtl .e-label,
.e-radio + label.e-bigger.e-small.e-right.e-rtl .e-label {
  padding-left: 32px;
  padding-right: 0;
}
.e-bigger .e-radio + label.e-small.e-right.e-rtl::after,
.e-radio + label.e-bigger.e-small.e-right.e-rtl::after {
  left: 6px;
  right: auto;
}
.e-bigger .e-radio + label.e-small.e-right.e-rtl .e-ripple-container,
.e-radio + label.e-bigger.e-small.e-right.e-rtl .e-ripple-container {
  left: -10px;
  right: auto;
}

.e-radio + label::before {
  background-color: #fff;
  border-color: #757575;
}
.e-radio + label.e-focus .e-ripple-container {
  background-color: rgba(0, 0, 0, 0.12);
}
.e-radio + label .e-label {
  color: rgba(0, 0, 0, 0.87);
}
.e-radio + label .e-ripple-element {
  background-color: rgba(227, 22, 91, 0.26);
}
.e-radio + label:active .e-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.e-radio:focus + label::before {
  border-color: #e3165b;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-radio:focus + label::before {
  border-color: #757575;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-radio:hover + label::before {
  border-color: #757575;
}
.e-radio:checked + label::before {
  background-color: #fff;
  border-color: #e3165b;
}
.e-radio:checked + label::after {
  background-color: #e3165b;
  color: #e3165b;
}
.e-radio:checked + label:active .e-ripple-element {
  background-color: rgba(227, 22, 91, 0.26);
}
.e-radio:checked + .e-focus .e-ripple-container {
  background-color: rgba(227, 22, 91, 0.26);
}
.e-radio:checked + .e-focus::before {
  outline: #fff 0 solid;
  outline-offset: 0;
}
.e-radio:checked:focus + label::before {
  border-color: #e3165b;
}
.e-radio:checked:focus + label::after {
  background-color: #e3165b;
}
.e-radio:checked + label:hover::before {
  border-color: #e3165b;
}
.e-radio:checked + label:hover::after {
  background-color: #e3165b;
}
.e-radio:disabled + label {
  cursor: default;
  pointer-events: none;
}
.e-radio:disabled + label::before {
  background-color: transparent;
  border-color: #bdbdbd;
  cursor: default;
}
.e-radio:disabled + label .e-ripple-container {
  background-color: transparent;
}
.e-radio:disabled + label .e-ripple-container::after {
  background-color: transparent;
  cursor: default;
}
.e-radio:disabled + label .e-label {
  color: #bdbdbd;
}
.e-radio:disabled:checked + label::before {
  background-color: transparent;
  border-color: #bdbdbd;
}
.e-radio:disabled:checked + label::after {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
  cursor: default;
}
.e-radio:disabled:checked + label .e-ripple-container, .e-radio:disabled:checked + label .e-ripple-container::after {
  background-color: transparent;
}

.e-switch-wrapper,
.e-css.e-switch-wrapper {
  cursor: pointer;
  display: inline-block;
  height: 12px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 34px;
}
.e-switch-wrapper .e-switch,
.e-css.e-switch-wrapper .e-switch {
  /* stylelint-disable property-no-vendor-prefix */
  -moz-appearance: none;
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}

.e-switch-wrapper .e-switch-inner,
.e-css.e-switch-wrapper .e-switch-inner {
  -ms-transition: all 0.08s linear;
  -webkit-transition: all 0.08s linear;
  border: none;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 0.08s linear;
  width: 100%;
}

.e-switch-wrapper .e-switch-on, .e-switch-wrapper .e-switch-off,
.e-css.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-off {
  -ms-transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "";
  font-size: small;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  -webkit-transition: -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}

.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-on {
  left: -100%;
  text-indent: -9999px;
}

.e-switch-wrapper .e-switch-off,
.e-css.e-switch-wrapper .e-switch-off {
  left: 0;
  opacity: 0.42;
  text-indent: -9999px;
}

.e-switch-wrapper .e-switch-handle,
.e-css.e-switch-wrapper .e-switch-handle {
  -ms-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  border-radius: 50%;
  bottom: 0;
  height: 18px;
  left: 0;
  margin: auto 0;
  position: absolute;
  top: 0;
  transition: all 0.2s linear;
  width: 18px;
}

.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on {
  left: 0;
  opacity: 0.54;
}

.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-off,
.e-css.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-off {
  left: 100%;
}

.e-switch-wrapper .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
  left: 100%;
  margin-left: -18px;
}

.e-switch-wrapper.e-switch-disabled,
.e-css.e-switch-wrapper.e-switch-disabled {
  cursor: default;
}

.e-switch-wrapper .e-ripple-container,
.e-css.e-switch-wrapper .e-ripple-container {
  border-radius: 50%;
  bottom: -9px;
  height: 52px;
  left: -17px;
  pointer-events: none;
  position: absolute;
  top: -17px;
  width: 52px;
  z-index: 1;
}
.e-switch-wrapper.e-rtl .e-switch-handle,
.e-css.e-switch-wrapper.e-rtl .e-switch-handle {
  left: 100%;
  margin-left: -18px;
}
.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on {
  left: 0;
}
.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off {
  left: -100%;
}
.e-switch-wrapper.e-rtl .e-switch-on,
.e-css.e-switch-wrapper.e-rtl .e-switch-on {
  left: 100%;
}
.e-switch-wrapper.e-rtl .e-switch-off,
.e-css.e-switch-wrapper.e-rtl .e-switch-off {
  left: 0;
}
.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
  border-radius: 50%;
  height: 18px;
  left: 0;
  margin: auto 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  width: 18px;
}

.e-switch-wrapper.e-small,
.e-css.e-switch-wrapper.e-small {
  height: 10px;
  width: 26px;
}
.e-switch-wrapper.e-small .e-switch-handle,
.e-css.e-switch-wrapper.e-small .e-switch-handle {
  height: 16px;
  width: 16px;
}
.e-switch-wrapper.e-small .e-ripple-container,
.e-css.e-switch-wrapper.e-small .e-ripple-container {
  border-radius: 50%;
  height: 36px;
  left: -10px;
  pointer-events: none;
  position: absolute;
  top: -10px;
  width: 36px;
  z-index: 1;
}
.e-switch-wrapper.e-small .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-small .e-switch-handle.e-switch-active {
  left: 100%;
  margin-left: -16px;
}
.e-switch-wrapper.e-small .e-switch-on, .e-switch-wrapper.e-small .e-switch-off,
.e-css.e-switch-wrapper.e-small .e-switch-on,
.e-css.e-switch-wrapper.e-small .e-switch-off {
  font-size: 9px;
}
.e-switch-wrapper.e-small .e-switch-on,
.e-css.e-switch-wrapper.e-small .e-switch-on {
  text-indent: -9999px;
}
.e-switch-wrapper.e-small .e-switch-off,
.e-css.e-switch-wrapper.e-small .e-switch-off {
  text-indent: -9999px;
}
.e-switch-wrapper.e-small.e-rtl .e-switch-handle,
.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle {
  left: 100%;
  margin-left: -16px;
}
.e-switch-wrapper.e-small.e-rtl .e-switch-handle,
.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle {
  height: 16px;
  width: 16px;
}
.e-switch-wrapper.e-small.e-rtl .e-switch-on,
.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-on {
  left: 100%;
  opacity: 0.54;
}
.e-switch-wrapper.e-small.e-rtl .e-switch-off,
.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-off {
  left: 0;
}
.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-on {
  left: 0;
}
.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-off {
  left: -100%;
}
.e-switch-wrapper.e-small.e-rtl .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle.e-switch-active {
  left: 16px;
}

*.e-small .e-switch-wrapper,
*.e-small.e-switch-wrapper,
*.e-small .e-css.e-switch-wrapper,
*.e-small.e-css.e-switch-wrapper {
  height: 10px;
  width: 26px;
}
*.e-small .e-switch-wrapper .e-switch-handle,
*.e-small.e-switch-wrapper .e-switch-handle,
*.e-small .e-css.e-switch-wrapper .e-switch-handle,
*.e-small.e-css.e-switch-wrapper .e-switch-handle {
  height: 16px;
  width: 16px;
}
*.e-small .e-switch-wrapper .e-ripple-container,
*.e-small.e-switch-wrapper .e-ripple-container,
*.e-small .e-css.e-switch-wrapper .e-ripple-container,
*.e-small.e-css.e-switch-wrapper .e-ripple-container {
  border-radius: 50%;
  height: 36px;
  left: -10px;
  pointer-events: none;
  position: absolute;
  top: -10px;
  width: 36px;
  z-index: 1;
}
*.e-small .e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-small.e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-small .e-css.e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-small.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
  left: 100%;
  margin-left: -16px;
}
*.e-small .e-switch-wrapper .e-switch-on, *.e-small .e-switch-wrapper .e-switch-off,
*.e-small.e-switch-wrapper .e-switch-on,
*.e-small.e-switch-wrapper .e-switch-off,
*.e-small .e-css.e-switch-wrapper .e-switch-on,
*.e-small .e-css.e-switch-wrapper .e-switch-off,
*.e-small.e-css.e-switch-wrapper .e-switch-on,
*.e-small.e-css.e-switch-wrapper .e-switch-off {
  font-size: 9px;
}
*.e-small .e-switch-wrapper .e-switch-on,
*.e-small.e-switch-wrapper .e-switch-on,
*.e-small .e-css.e-switch-wrapper .e-switch-on,
*.e-small.e-css.e-switch-wrapper .e-switch-on {
  text-indent: -9999px;
}
*.e-small .e-switch-wrapper .e-switch-off,
*.e-small.e-switch-wrapper .e-switch-off,
*.e-small .e-css.e-switch-wrapper .e-switch-off,
*.e-small.e-css.e-switch-wrapper .e-switch-off {
  text-indent: -9999px;
}
*.e-small .e-switch-wrapper.e-rtl .e-switch-handle,
*.e-small.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-handle {
  left: 100%;
  margin-left: -16px;
}
*.e-small .e-switch-wrapper.e-rtl .e-switch-handle,
*.e-small.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-handle {
  height: 16px;
  width: 16px;
}
*.e-small .e-switch-wrapper.e-rtl .e-switch-on,
*.e-small.e-switch-wrapper.e-rtl .e-switch-on,
*.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-on,
*.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-on {
  left: 100%;
  opacity: 0.54;
}
*.e-small .e-switch-wrapper.e-rtl .e-switch-off,
*.e-small.e-switch-wrapper.e-rtl .e-switch-off,
*.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-off,
*.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-off {
  left: 0;
}
*.e-small .e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-small.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on {
  left: 0;
}
*.e-small .e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-small.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off {
  left: -100%;
}
*.e-small .e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-small.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
  left: 16px;
}

*.e-bigger.e-small .e-switch-wrapper,
*.e-bigger.e-small.e-switch-wrapper,
*.e-bigger.e-small .e-css.e-switch-wrapper,
*.e-bigger.e-small.e-css.e-switch-wrapper {
  height: 12px;
  width: 34px;
}
*.e-bigger.e-small .e-switch-wrapper .e-switch-handle,
*.e-bigger.e-small.e-switch-wrapper .e-switch-handle,
*.e-bigger.e-small .e-css.e-switch-wrapper .e-switch-handle,
*.e-bigger.e-small.e-css.e-switch-wrapper .e-switch-handle {
  height: 18px;
  left: 0;
  top: 0;
  width: 18px;
}
*.e-bigger.e-small .e-switch-wrapper .e-ripple-container,
*.e-bigger.e-small.e-switch-wrapper .e-ripple-container,
*.e-bigger.e-small .e-css.e-switch-wrapper .e-ripple-container,
*.e-bigger.e-small.e-css.e-switch-wrapper .e-ripple-container {
  border-radius: 50%;
  height: 50px;
  left: -16px;
  pointer-events: none;
  position: absolute;
  top: -16px;
  width: 50px;
  z-index: 1;
}
*.e-bigger.e-small .e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-bigger.e-small.e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-bigger.e-small .e-css.e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-bigger.e-small.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
  left: 100%;
  margin-left: -18px;
}
*.e-bigger.e-small .e-switch-wrapper .e-switch-on, *.e-bigger.e-small .e-switch-wrapper .e-switch-off,
*.e-bigger.e-small.e-switch-wrapper .e-switch-on,
*.e-bigger.e-small.e-switch-wrapper .e-switch-off,
*.e-bigger.e-small .e-css.e-switch-wrapper .e-switch-on,
*.e-bigger.e-small .e-css.e-switch-wrapper .e-switch-off,
*.e-bigger.e-small.e-css.e-switch-wrapper .e-switch-on,
*.e-bigger.e-small.e-css.e-switch-wrapper .e-switch-off {
  font-size: 9px;
}
*.e-bigger.e-small .e-switch-wrapper .e-switch-on,
*.e-bigger.e-small.e-switch-wrapper .e-switch-on,
*.e-bigger.e-small .e-css.e-switch-wrapper .e-switch-on,
*.e-bigger.e-small.e-css.e-switch-wrapper .e-switch-on {
  text-indent: -9999px;
}
*.e-bigger.e-small .e-switch-wrapper .e-switch-off,
*.e-bigger.e-small.e-switch-wrapper .e-switch-off,
*.e-bigger.e-small .e-css.e-switch-wrapper .e-switch-off,
*.e-bigger.e-small.e-css.e-switch-wrapper .e-switch-off {
  text-indent: -9999px;
}
*.e-bigger.e-small .e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-small.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-handle {
  left: 100%;
  margin-left: -18px;
}
*.e-bigger.e-small .e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-small.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-handle {
  height: 18px;
  width: 18px;
}
*.e-bigger.e-small .e-switch-wrapper.e-rtl .e-switch-on,
*.e-bigger.e-small.e-switch-wrapper.e-rtl .e-switch-on,
*.e-bigger.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-on,
*.e-bigger.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-on {
  left: 100%;
  opacity: 0.54;
}
*.e-bigger.e-small .e-switch-wrapper.e-rtl .e-switch-off,
*.e-bigger.e-small.e-switch-wrapper.e-rtl .e-switch-off,
*.e-bigger.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-off,
*.e-bigger.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-off {
  left: 0;
}
*.e-bigger.e-small .e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger.e-small.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on {
  left: 0;
}
*.e-bigger.e-small .e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger.e-small.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off {
  left: -100%;
}
*.e-bigger.e-small .e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger.e-small.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger.e-small .e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger.e-small.e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
  left: 18px;
}

*.e-bigger .e-switch-wrapper,
*.e-bigger.e-switch-wrapper,
*.e-bigger .e-css.e-switch-wrapper,
*.e-bigger.e-css.e-switch-wrapper {
  height: 14px;
  width: 36px;
}
*.e-bigger .e-switch-wrapper .e-switch-handle,
*.e-bigger.e-switch-wrapper .e-switch-handle,
*.e-bigger .e-css.e-switch-wrapper .e-switch-handle,
*.e-bigger.e-css.e-switch-wrapper .e-switch-handle {
  height: 20px;
  left: 0;
  top: 0;
  width: 20px;
}
*.e-bigger .e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-bigger.e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-bigger .e-css.e-switch-wrapper .e-switch-handle.e-switch-active,
*.e-bigger.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
  left: 100%;
  margin-left: -20px;
}
*.e-bigger .e-switch-wrapper .e-switch-on, *.e-bigger .e-switch-wrapper .e-switch-off,
*.e-bigger.e-switch-wrapper .e-switch-on,
*.e-bigger.e-switch-wrapper .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper .e-switch-off {
  font-size: 0;
}
*.e-bigger .e-switch-wrapper .e-switch-on,
*.e-bigger.e-switch-wrapper .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper .e-switch-on {
  text-indent: -9999px;
}
*.e-bigger .e-switch-wrapper .e-switch-off,
*.e-bigger.e-switch-wrapper .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper .e-switch-off {
  text-indent: -9999px;
}
*.e-bigger .e-switch-wrapper .e-ripple-container,
*.e-bigger.e-switch-wrapper .e-ripple-container,
*.e-bigger .e-css.e-switch-wrapper .e-ripple-container,
*.e-bigger.e-css.e-switch-wrapper .e-ripple-container {
  height: 52px;
  left: -16px;
  top: -16px;
  width: 52px;
}
*.e-bigger .e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger .e-css.e-switch-wrapper.e-rtl .e-switch-handle,
*.e-bigger.e-css.e-switch-wrapper.e-rtl .e-switch-handle {
  height: 20px;
  left: 100%;
  margin-left: -20px;
  top: 0;
  width: 20px;
}
*.e-bigger .e-switch-wrapper.e-rtl .e-switch-on,
*.e-bigger.e-switch-wrapper.e-rtl .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper.e-rtl .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper.e-rtl .e-switch-on {
  left: 100%;
  opacity: 0.54;
}
*.e-bigger .e-switch-wrapper.e-rtl .e-switch-off,
*.e-bigger.e-switch-wrapper.e-rtl .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper.e-rtl .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper.e-rtl .e-switch-off {
  left: 0;
}
*.e-bigger .e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-on {
  left: 0;
}
*.e-bigger .e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off {
  left: -100%;
}
*.e-bigger .e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger .e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger.e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
  left: 20px;
}
*.e-bigger .e-switch-wrapper.e-small,
*.e-bigger.e-switch-wrapper.e-small,
*.e-bigger .e-css.e-switch-wrapper.e-small,
*.e-bigger.e-css.e-switch-wrapper.e-small {
  height: 12px;
  width: 34px;
}
*.e-bigger .e-switch-wrapper.e-small .e-switch-handle,
*.e-bigger.e-switch-wrapper.e-small .e-switch-handle,
*.e-bigger .e-css.e-switch-wrapper.e-small .e-switch-handle,
*.e-bigger.e-css.e-switch-wrapper.e-small .e-switch-handle {
  height: 18px;
  left: 0;
  top: 0;
  width: 18px;
}
*.e-bigger .e-switch-wrapper.e-small .e-ripple-container,
*.e-bigger.e-switch-wrapper.e-small .e-ripple-container,
*.e-bigger .e-css.e-switch-wrapper.e-small .e-ripple-container,
*.e-bigger.e-css.e-switch-wrapper.e-small .e-ripple-container {
  border-radius: 50%;
  height: 50px;
  left: -16px;
  pointer-events: none;
  position: absolute;
  top: -16px;
  width: 50px;
  z-index: 1;
}
*.e-bigger .e-switch-wrapper.e-small .e-switch-handle.e-switch-active,
*.e-bigger.e-switch-wrapper.e-small .e-switch-handle.e-switch-active,
*.e-bigger .e-css.e-switch-wrapper.e-small .e-switch-handle.e-switch-active,
*.e-bigger.e-css.e-switch-wrapper.e-small .e-switch-handle.e-switch-active {
  left: 100%;
  margin-left: -18px;
}
*.e-bigger .e-switch-wrapper.e-small .e-switch-on, *.e-bigger .e-switch-wrapper.e-small .e-switch-off,
*.e-bigger.e-switch-wrapper.e-small .e-switch-on,
*.e-bigger.e-switch-wrapper.e-small .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper.e-small .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper.e-small .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper.e-small .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper.e-small .e-switch-off {
  font-size: 9px;
}
*.e-bigger .e-switch-wrapper.e-small .e-switch-on,
*.e-bigger.e-switch-wrapper.e-small .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper.e-small .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper.e-small .e-switch-on {
  text-indent: -9999px;
}
*.e-bigger .e-switch-wrapper.e-small .e-switch-off,
*.e-bigger.e-switch-wrapper.e-small .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper.e-small .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper.e-small .e-switch-off {
  text-indent: -9999px;
}
*.e-bigger .e-switch-wrapper.e-small.e-rtl .e-switch-handle,
*.e-bigger.e-switch-wrapper.e-small.e-rtl .e-switch-handle,
*.e-bigger .e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle,
*.e-bigger.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle {
  left: 100%;
  margin-left: -18px;
}
*.e-bigger .e-switch-wrapper.e-small.e-rtl .e-switch-handle,
*.e-bigger.e-switch-wrapper.e-small.e-rtl .e-switch-handle,
*.e-bigger .e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle,
*.e-bigger.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle {
  height: 18px;
  width: 18px;
}
*.e-bigger .e-switch-wrapper.e-small.e-rtl .e-switch-on,
*.e-bigger.e-switch-wrapper.e-small.e-rtl .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper.e-small.e-rtl .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-on {
  left: 100%;
  opacity: 0.54;
}
*.e-bigger .e-switch-wrapper.e-small.e-rtl .e-switch-off,
*.e-bigger.e-switch-wrapper.e-small.e-rtl .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper.e-small.e-rtl .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-off {
  left: 0;
}
*.e-bigger .e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger .e-css.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-on,
*.e-bigger.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-on {
  left: 0;
}
*.e-bigger .e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger .e-css.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
*.e-bigger.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-inner.e-switch-active .e-switch-off {
  left: -100%;
}
*.e-bigger .e-switch-wrapper.e-small.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger.e-switch-wrapper.e-small.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger .e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle.e-switch-active,
*.e-bigger.e-css.e-switch-wrapper.e-small.e-rtl .e-switch-handle.e-switch-active {
  left: 18px;
}

.e-switch-wrapper,
.e-css.e-switch-wrapper {
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-tap-highlight-color: transparent;
}
.e-switch-wrapper .e-switch-off,
.e-css.e-switch-wrapper .e-switch-off {
  background-color: #000;
  color: #fff;
}
.e-switch-wrapper .e-switch-handle,
.e-css.e-switch-wrapper .e-switch-handle {
  background-color: #f5f5f5;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.e-switch-wrapper .e-switch-on,
.e-css.e-switch-wrapper .e-switch-on {
  background-color: #e3165b;
  color: #fff;
}
.e-switch-wrapper .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper .e-switch-handle.e-switch-active {
  background-color: #e3165b;
}
.e-switch-wrapper .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper .e-switch-inner.e-switch-active {
  background-color: #fff;
  border-color: transparent;
}
.e-switch-wrapper .e-switch-inner,
.e-css.e-switch-wrapper .e-switch-inner {
  background-color: initial;
}
.e-switch-wrapper .e-ripple-element,
.e-css.e-switch-wrapper .e-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.e-switch-wrapper .e-ripple-check .e-ripple-element,
.e-css.e-switch-wrapper .e-ripple-check .e-ripple-element {
  background-color: rgba(227, 22, 91, 0.12);
}
.e-switch-wrapper.e-switch-disabled .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-switch-disabled .e-switch-handle.e-switch-active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-switch-wrapper.e-switch-disabled .e-switch-handle,
.e-css.e-switch-wrapper.e-switch-disabled .e-switch-handle {
  background-color: #bdbdbd;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-switch-wrapper.e-switch-disabled .e-switch-inner .e-switch-off,
.e-css.e-switch-wrapper.e-switch-disabled .e-switch-inner .e-switch-off {
  opacity: 0.12;
  background-color: #000;
  border-color: #bdbdbd;
  color: transparent;
}
.e-switch-wrapper.e-switch-disabled .e-switch-inner .e-switch-on,
.e-css.e-switch-wrapper.e-switch-disabled .e-switch-inner .e-switch-on {
  color: transparent;
  opacity: 0.12;
  background-color: #000;
}
.e-switch-wrapper.e-switch-disabled .e-switch-inner,
.e-css.e-switch-wrapper.e-switch-disabled .e-switch-inner {
  background-color: #000;
  border-color: transparent;
  opacity: 0.12;
}
.e-switch-wrapper.e-switch-disabled:hover .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-switch-disabled:hover .e-switch-inner.e-switch-active {
  background-color: #000;
  border-color: transparent;
}
.e-switch-wrapper.e-switch-disabled:hover .e-switch-inner,
.e-css.e-switch-wrapper.e-switch-disabled:hover .e-switch-inner {
  border-color: transparent;
  color: transparent;
}
.e-switch-wrapper.e-switch-disabled:hover .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper.e-switch-disabled:hover .e-switch-inner.e-switch-active .e-switch-on {
  color: transparent;
  background-color: #000;
}
.e-switch-wrapper.e-switch-disabled:hover .e-switch-handle,
.e-css.e-switch-wrapper.e-switch-disabled:hover .e-switch-handle {
  background-color: #bdbdbd;
}
.e-switch-wrapper.e-switch-disabled:hover .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-switch-disabled:hover .e-switch-handle.e-switch-active {
  background-color: #bdbdbd;
}
.e-switch-wrapper:hover .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper:hover .e-switch-inner.e-switch-active {
  background-color: transparent;
  border-color: transparent;
}
.e-switch-wrapper:hover .e-switch-inner,
.e-css.e-switch-wrapper:hover .e-switch-inner {
  background-color: transparent;
  border-color: inherit;
}
.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on {
  background-color: #e3165b;
  color: #fff;
}
.e-switch-wrapper:hover .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper:hover .e-switch-handle.e-switch-active {
  background-color: #e3165b;
}
.e-switch-wrapper:not(.e-switch-disabled):hover .e-switch-handle:not(.e-switch-active),
.e-css.e-switch-wrapper:not(.e-switch-disabled):hover .e-switch-handle:not(.e-switch-active) {
  background-color: #f5f5f5;
}
.e-switch-wrapper.e-focus .e-switch-inner, .e-switch-wrapper:focus .e-switch-inner,
.e-css.e-switch-wrapper.e-focus .e-switch-inner,
.e-css.e-switch-wrapper:focus .e-switch-inner {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  outline-offset: initial;
}
.e-switch-wrapper.e-focus .e-switch-inner.e-switch-active, .e-switch-wrapper:focus .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-focus .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper:focus .e-switch-inner.e-switch-active {
  background-color: transparent;
  border-color: transparent;
  outline: none;
}
.e-switch-wrapper.e-focus .e-ripple-container, .e-switch-wrapper:focus .e-ripple-container,
.e-css.e-switch-wrapper.e-focus .e-ripple-container,
.e-css.e-switch-wrapper:focus .e-ripple-container {
  background-color: rgba(0, 0, 0, 0.12);
}
.e-switch-wrapper.e-focus .e-ripple-check.e-ripple-container, .e-switch-wrapper:focus .e-ripple-check.e-ripple-container,
.e-css.e-switch-wrapper.e-focus .e-ripple-check.e-ripple-container,
.e-css.e-switch-wrapper:focus .e-ripple-check.e-ripple-container {
  background-color: rgba(227, 22, 91, 0.12);
}
.e-switch-wrapper.e-active .e-switch-inner, .e-switch-wrapper:active .e-switch-inner,
.e-css.e-switch-wrapper.e-active .e-switch-inner,
.e-css.e-switch-wrapper:active .e-switch-inner {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  outline-offset: initial;
}
.e-switch-wrapper.e-active .e-switch-inner.e-switch-active, .e-switch-wrapper:active .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-active .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper:active .e-switch-inner.e-switch-active {
  background-color: transparent;
  border-color: transparent;
  outline: none;
}
.e-switch-wrapper.e-active .e-ripple-container, .e-switch-wrapper:active .e-ripple-container,
.e-css.e-switch-wrapper.e-active .e-ripple-container,
.e-css.e-switch-wrapper:active .e-ripple-container {
  background-color: rgba(0, 0, 0, 0.12);
}
.e-switch-wrapper.e-active .e-ripple-check.e-ripple-container, .e-switch-wrapper:active .e-ripple-check.e-ripple-container,
.e-css.e-switch-wrapper.e-active .e-ripple-check.e-ripple-container,
.e-css.e-switch-wrapper:active .e-ripple-check.e-ripple-container {
  background-color: rgba(227, 22, 91, 0.12);
}
.e-switch-wrapper.e-rtl.e-focus .e-switch-on,
.e-css.e-switch-wrapper.e-rtl.e-focus .e-switch-on {
  background-color: rgba(227, 22, 91, 0.54);
}
.e-switch-wrapper.e-rtl.e-focus .e-switch-off,
.e-css.e-switch-wrapper.e-rtl.e-focus .e-switch-off {
  background-color: rgba(227, 22, 91, 0.54);
}
.e-switch-wrapper.e-rtl.e-focus .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-rtl.e-focus .e-switch-inner.e-switch-active {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  outline: none;
}
.e-switch-wrapper.e-rtl .e-switch-on,
.e-css.e-switch-wrapper.e-rtl .e-switch-on {
  background-color: #e3165b;
}
.e-switch-wrapper.e-rtl .e-switch-handle,
.e-css.e-switch-wrapper.e-rtl .e-switch-handle {
  background-color: #f5f5f5;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off,
.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active .e-switch-off {
  background-color: #000;
}
.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-rtl .e-switch-handle.e-switch-active {
  background-color: #e3165b;
}
.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-rtl .e-switch-inner.e-switch-active {
  background-color: #fff;
  border-color: transparent;
}
.e-switch-wrapper.e-rtl:hover .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-rtl:hover .e-switch-inner.e-switch-active {
  background-color: transparent;
  border-color: transparent;
}
.e-switch-wrapper.e-rtl:hover .e-switch-inner,
.e-css.e-switch-wrapper.e-rtl:hover .e-switch-inner {
  border-color: inherit;
}
.e-switch-wrapper.e-rtl:hover .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper.e-rtl:hover .e-switch-inner.e-switch-active .e-switch-on {
  background-color: #e3165b;
}
.e-switch-wrapper.e-rtl:hover .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-rtl:hover .e-switch-handle.e-switch-active {
  background-color: #e3165b;
}
.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-inner .e-switch-on,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-inner .e-switch-on {
  color: transparent;
  opacity: 0.12;
  background-color: #000;
}
.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-inner .e-switch-off,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-inner .e-switch-off {
  opacity: 0.12;
  background-color: #000;
  color: transparent;
}
.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-handle,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-handle {
  background-color: #bdbdbd;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-handle.e-switch-active {
  background-color: #bdbdbd;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-inner,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled .e-switch-inner {
  background-color: #000;
  border-color: transparent;
  opacity: 0.12;
}
.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-inner.e-switch-active .e-switch-on,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-inner.e-switch-active .e-switch-on {
  color: transparent;
  background-color: #000;
}
.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-inner.e-switch-active {
  background-color: #000;
  border-color: transparent;
}
.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-inner,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-inner {
  border-color: transparent;
  color: transparent;
}
.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-handle.e-switch-active,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-handle.e-switch-active {
  background-color: #bdbdbd;
}
.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-handle,
.e-css.e-switch-wrapper.e-rtl.e-switch-disabled:hover .e-switch-handle {
  background-color: #bdbdbd;
}
.e-switch-wrapper .e-switch:focus,
.e-css.e-switch-wrapper .e-switch:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-switch-wrapper.e-small.e-rtl.e-switch-disabled:hover .e-switch-inner.e-switch-active,
.e-css.e-switch-wrapper.e-small.e-rtl.e-switch-disabled:hover .e-switch-inner.e-switch-active {
  background-color: #000;
}

.e-chip-list .e-chip-delete.e-dlt-btn::before {
  content: "\e208";
}
.e-chip-list.e-multi-selection .e-chip::before {
  content: "\e933";
}

.e-chip-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
}
.e-chip-list.e-chip,
.e-chip-list .e-chip {
  -webkit-tap-highlight-color: transparent;
  border: 0 solid;
  border-radius: 14px;
  font-size: 13px;
  margin: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 400;
  height: 28px;
  line-height: 1.5em;
  outline: none;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
  -webkit-transition: -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.e-chip-list.e-chip .e-chip-avatar,
.e-chip-list .e-chip .e-chip-avatar {
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  font-size: 13px;
  margin: 0 8px 0 -10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
}
.e-chip-list.e-chip .e-chip-avatar-wrap, .e-chip-list.e-chip.e-chip-avatar-wrap,
.e-chip-list .e-chip .e-chip-avatar-wrap,
.e-chip-list .e-chip.e-chip-avatar-wrap {
  border-radius: 14px;
}
.e-chip-list.e-chip .e-chip-icon,
.e-chip-list .e-chip .e-chip-icon {
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 8px 0 -6px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
}
.e-chip-list.e-chip .e-chip-text,
.e-chip-list .e-chip .e-chip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-chip-list.e-chip .e-chip-delete,
.e-chip-list .e-chip .e-chip-delete {
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 -2px 0 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
}
.e-chip-list.e-chip .e-chip-delete.e-dlt-btn::before,
.e-chip-list .e-chip .e-chip-delete.e-dlt-btn::before {
  font-family: "e-icons";
}
.e-chip-list.e-chip .image-url,
.e-chip-list .e-chip .image-url {
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 8px 0 -6px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
}
.e-chip-list.e-chip .trailing-icon-url,
.e-chip-list .e-chip .trailing-icon-url {
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 -2px 0 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  font-family: "e-icons";
}
.e-chip-list:not(.e-chip) {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.e-chip-list.e-multi-selection .e-chip::before {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "e-icons";
  height: 20px;
  width: 20px;
  margin: 0 4px 0 -6px;
  margin-top: 0;
  overflow: hidden;
  -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.e-chip-list.e-multi-selection .e-chip:not(.e-chip-icon-wrap):not(.e-chip-avatar-wrap)::before {
  width: 0;
}
.e-chip-list.e-multi-selection .e-chip.e-chip-icon-wrap::before, .e-chip-list.e-multi-selection .e-chip.e-chip-avatar-wrap::before {
  display: none;
}
.e-chip-list.e-multi-selection .e-chip.e-chip-avatar-wrap::before {
  height: 28px;
  width: 28px;
  margin: 0 8px 0 -10px;
  margin-top: 0;
}
.e-chip-list.e-multi-selection .e-chip.e-active .e-chip-icon, .e-chip-list.e-multi-selection .e-chip.e-active .e-chip-avatar {
  display: none;
}
.e-chip-list.e-multi-selection .e-chip.e-active.e-chip-icon-wrap::before, .e-chip-list.e-multi-selection .e-chip.e-active.e-chip-avatar-wrap::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-chip-list.e-multi-selection .e-chip.e-active:not(.e-chip-icon-wrap):not(.e-chip-avatar-wrap)::before {
  width: 20px;
}
.e-chip-list.e-rtl.e-chip .e-chip-avatar, .e-chip-list.e-rtl .e-chip .e-chip-avatar {
  margin: 0 -10px 0 8px;
}
.e-chip-list.e-rtl.e-chip .e-chip-icon, .e-chip-list.e-rtl .e-chip .e-chip-icon {
  margin: 0 -6px 0 8px;
}
.e-chip-list.e-rtl.e-chip .e-chip-delete, .e-chip-list.e-rtl .e-chip .e-chip-delete {
  margin: 0 8px 0 -2px;
}
.e-chip-list.e-rtl.e-chip .e-chip-avatar-wrap, .e-chip-list.e-rtl.e-chip.e-chip-avatar-wrap, .e-chip-list.e-rtl .e-chip .e-chip-avatar-wrap, .e-chip-list.e-rtl .e-chip.e-chip-avatar-wrap {
  border-radius: 14px;
}
.e-chip-list.e-rtl.e-chip .trailing-icon-url, .e-chip-list.e-rtl .e-chip .trailing-icon-url {
  margin: 0 8px 0 -2px;
}
.e-chip-list.e-rtl.e-multi-selection .e-chip::before {
  margin: 0 -6px 0 8px;
  margin-top: 0;
}
.e-chip-list.e-rtl.e-multi-selection .e-chip.e-chip-avatar-wrap::before {
  margin: 0 -10px 0 8px;
  margin-top: 0;
}

.e-bigger .e-chip-list.e-chip, .e-bigger .e-chip-list .e-chip,
.e-bigger.e-chip-list.e-chip,
.e-bigger.e-chip-list .e-chip {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  padding: 0 12px;
}
.e-bigger .e-chip-list .e-chip-avatar,
.e-bigger.e-chip-list .e-chip-avatar {
  height: 32px;
  width: 32px;
  margin: 0 8px 0 -12px;
  font-size: 15px;
}
.e-bigger .e-chip-list .e-chip-avatar-wrap, .e-bigger .e-chip-list.e-chip-avatar-wrap,
.e-bigger.e-chip-list .e-chip-avatar-wrap,
.e-bigger.e-chip-list.e-chip-avatar-wrap {
  border-radius: 16px;
}
.e-bigger .e-chip-list .e-chip-icon,
.e-bigger.e-chip-list .e-chip-icon {
  font-size: 16px;
  height: 24px;
  width: 24px;
  margin: 0 8px 0 -8px;
}
.e-bigger .e-chip-list .e-chip-delete,
.e-bigger.e-chip-list .e-chip-delete {
  height: 18px;
  width: 18px;
  margin: 0 -4px 0 8px;
  font-size: 16px;
}
.e-bigger .e-chip-list .trailing-icon-url,
.e-bigger.e-chip-list .trailing-icon-url {
  height: 18px;
  width: 18px;
  margin: 0 -4px 0 8px;
  font-size: 16px;
}
.e-bigger .e-chip-list.e-multi-selection .e-chip::before,
.e-bigger.e-chip-list.e-multi-selection .e-chip::before {
  height: 24px;
  width: 24px;
  margin: 0 4px 0 -8px;
  margin-top: 0;
}
.e-bigger .e-chip-list.e-multi-selection .e-chip.e-chip-avatar-wrap::before,
.e-bigger.e-chip-list.e-multi-selection .e-chip.e-chip-avatar-wrap::before {
  height: 32px;
  width: 32px;
  margin: 0 8px 0 -12px;
  margin-top: 0;
}
.e-bigger .e-chip-list.e-multi-selection .e-chip.e-active:not(.e-chip-icon-wrap):not(.e-chip-avatar-wrap)::before,
.e-bigger.e-chip-list.e-multi-selection .e-chip.e-active:not(.e-chip-icon-wrap):not(.e-chip-avatar-wrap)::before {
  width: 24px;
}
.e-bigger .e-chip-list.e-rtl.e-chip .e-chip-avatar, .e-bigger .e-chip-list.e-rtl .e-chip .e-chip-avatar,
.e-bigger.e-chip-list.e-rtl.e-chip .e-chip-avatar,
.e-bigger.e-chip-list.e-rtl .e-chip .e-chip-avatar {
  margin: 0 -12px 0 8px;
}
.e-bigger .e-chip-list.e-rtl.e-chip .e-chip-icon, .e-bigger .e-chip-list.e-rtl .e-chip .e-chip-icon,
.e-bigger.e-chip-list.e-rtl.e-chip .e-chip-icon,
.e-bigger.e-chip-list.e-rtl .e-chip .e-chip-icon {
  margin: 0 -8px 0 8px;
}
.e-bigger .e-chip-list.e-rtl.e-chip .e-chip-delete, .e-bigger .e-chip-list.e-rtl .e-chip .e-chip-delete,
.e-bigger.e-chip-list.e-rtl.e-chip .e-chip-delete,
.e-bigger.e-chip-list.e-rtl .e-chip .e-chip-delete {
  margin: 0 8px 0 -4px;
}
.e-bigger .e-chip-list.e-rtl.e-chip .e-chip-avatar-wrap, .e-bigger .e-chip-list.e-rtl.e-chip.e-chip-avatar-wrap, .e-bigger .e-chip-list.e-rtl .e-chip .e-chip-avatar-wrap, .e-bigger .e-chip-list.e-rtl .e-chip.e-chip-avatar-wrap,
.e-bigger.e-chip-list.e-rtl.e-chip .e-chip-avatar-wrap,
.e-bigger.e-chip-list.e-rtl.e-chip.e-chip-avatar-wrap,
.e-bigger.e-chip-list.e-rtl .e-chip .e-chip-avatar-wrap,
.e-bigger.e-chip-list.e-rtl .e-chip.e-chip-avatar-wrap {
  border-radius: 16px;
}
.e-bigger .e-chip-list.e-rtl.e-multi-selection .e-chip::before,
.e-bigger.e-chip-list.e-rtl.e-multi-selection .e-chip::before {
  margin: 0 -8px 0 8px;
  margin-top: 0;
}
.e-bigger .e-chip-list.e-rtl.e-multi-selection .e-chip.e-chip-avatar-wrap::before,
.e-bigger.e-chip-list.e-rtl.e-multi-selection .e-chip.e-chip-avatar-wrap::before {
  margin: 0 -12px 0 8px;
  margin-top: 0;
}
.e-bigger .e-chip-list.e-rtl .trailing-icon-url,
.e-bigger.e-chip-list.e-rtl .trailing-icon-url {
  margin: 0 8px 0 -4px;
}

.e-chip-list.e-selection .e-chip.e-active {
  background: #e3165b;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active .e-chip-icon,
.e-chip-list.e-selection .e-chip.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active .e-chip-avatar {
  background-color: #ab1145;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-focused {
  background: #ed4980;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-focused .e-chip-icon,
.e-chip-list.e-selection .e-chip.e-active.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-focused .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-selection .e-chip.e-active.e-disabled .e-chip-icon,
.e-chip-list.e-selection .e-chip.e-active.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-selection .e-chip.e-active.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-selection .e-chip.e-active.e-outline {
  background: #e3165b;
  border-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-outline .e-chip-icon,
.e-chip-list.e-selection .e-chip.e-active.e-outline .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-outline .e-chip-avatar {
  background-color: #ab1145;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-focused {
  background: #ed4980;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-focused .e-chip-icon,
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-focused .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-disabled .e-chip-icon,
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-selection .e-chip.e-active.e-outline.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-selection .e-chip:active {
  background: rgba(227, 22, 91, 0.16);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-selection .e-chip:active .e-chip-icon,
.e-chip-list.e-selection .e-chip:active .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-selection .e-chip:active .e-chip-avatar {
  background-color: rgba(171, 17, 69, 0.16);
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-selection .e-chip:active.e-outline {
  background: rgba(227, 22, 91, 0.16);
  border-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-selection .e-chip:active.e-outline .e-chip-icon,
.e-chip-list.e-selection .e-chip:active.e-outline .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-selection .e-chip:active.e-outline .e-chip-avatar {
  background-color: rgba(171, 17, 69, 0.16);
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip, .e-chip-list .e-chip {
  background: #e0e0e0;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip .e-chip-icon,
.e-chip-list.e-chip .e-chip-delete, .e-chip-list .e-chip .e-chip-icon,
.e-chip-list .e-chip .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip .e-chip-delete.e-dlt-btn {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip .e-chip-avatar, .e-chip-list .e-chip .e-chip-avatar {
  background-color: #c1c1c1;
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: rgba(0, 0, 0, 0.7);
}
.e-chip-list.e-chip:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #000;
}
.e-chip-list.e-chip:hover, .e-chip-list .e-chip:hover {
  background: #d6d6d6;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip:hover .e-chip-icon,
.e-chip-list.e-chip:hover .e-chip-delete, .e-chip-list .e-chip:hover .e-chip-icon,
.e-chip-list .e-chip:hover .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip:hover .e-chip-avatar, .e-chip-list .e-chip:hover .e-chip-avatar {
  background-color: #b7b7b7;
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-focused, .e-chip-list .e-chip.e-focused {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #c1c1c1;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-focused .e-chip-delete, .e-chip-list .e-chip.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-focused .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-focused .e-chip-avatar {
  background-color: #b7b7b7;
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-focused.e-active, .e-chip-list .e-chip.e-focused.e-active {
  background: #c1c1c1;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-chip-list.e-chip.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-focused.e-active .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-focused.e-active .e-chip-avatar {
  background-color: #a3a3a3;
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-active, .e-chip-list .e-chip.e-active {
  background: #bcbcbc;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-chip-list.e-chip.e-active .e-chip-icon,
.e-chip-list.e-chip.e-active .e-chip-delete, .e-chip-list .e-chip.e-active .e-chip-icon,
.e-chip-list .e-chip.e-active .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-active .e-chip-avatar, .e-chip-list .e-chip.e-active .e-chip-avatar {
  background-color: #9e9e9e;
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip:active, .e-chip-list .e-chip:active {
  background: #b7b7b7;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: 0 2px 1px -6px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 1px -6px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.e-chip-list.e-chip:active .e-chip-icon,
.e-chip-list.e-chip:active .e-chip-delete, .e-chip-list .e-chip:active .e-chip-icon,
.e-chip-list .e-chip:active .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip:active .e-chip-avatar, .e-chip-list .e-chip:active .e-chip-avatar {
  background-color: #999999;
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-disabled, .e-chip-list .e-chip.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
  opacity: 1;
  pointer-events: none;
}
.e-chip-list.e-chip.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-outline, .e-chip-list .e-chip.e-outline {
  background: transparent;
  border-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
  border-width: 1px;
}
.e-chip-list.e-chip.e-outline .e-chip-icon,
.e-chip-list.e-chip.e-outline .e-chip-delete, .e-chip-list .e-chip.e-outline .e-chip-icon,
.e-chip-list .e-chip.e-outline .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-outline .e-chip-delete.e-dlt-btn {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline .e-chip-avatar, .e-chip-list .e-chip.e-outline .e-chip-avatar {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: rgba(0, 0, 0, 0.7);
}
.e-chip-list.e-chip.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #000;
}
.e-chip-list.e-chip.e-outline:hover, .e-chip-list .e-chip.e-outline:hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-outline:hover .e-chip-icon,
.e-chip-list.e-chip.e-outline:hover .e-chip-delete, .e-chip-list .e-chip.e-outline:hover .e-chip-icon,
.e-chip-list .e-chip.e-outline:hover .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline:hover .e-chip-avatar, .e-chip-list .e-chip.e-outline:hover .e-chip-avatar {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-outline.e-focused, .e-chip-list .e-chip.e-outline.e-focused {
  background: rgba(0, 0, 0, 0.12);
  border-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-outline.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-outline.e-focused .e-chip-delete, .e-chip-list .e-chip.e-outline.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-outline.e-focused .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-outline.e-focused .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline.e-focused.e-active, .e-chip-list .e-chip.e-outline.e-focused.e-active {
  background: rgba(0, 0, 0, 0.12);
  border-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-outline.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-outline.e-focused.e-active .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-outline.e-focused.e-active .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline.e-active, .e-chip-list .e-chip.e-outline.e-active {
  background: rgba(0, 0, 0, 0.14);
  border-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-outline.e-active .e-chip-icon,
.e-chip-list.e-chip.e-outline.e-active .e-chip-delete, .e-chip-list .e-chip.e-outline.e-active .e-chip-icon,
.e-chip-list .e-chip.e-outline.e-active .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline.e-active .e-chip-avatar, .e-chip-list .e-chip.e-outline.e-active .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.14);
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline:active, .e-chip-list .e-chip.e-outline:active {
  background: rgba(0, 0, 0, 0.16);
  border-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}
.e-chip-list.e-chip.e-outline:active .e-chip-icon,
.e-chip-list.e-chip.e-outline:active .e-chip-delete, .e-chip-list .e-chip.e-outline:active .e-chip-icon,
.e-chip-list .e-chip.e-outline:active .e-chip-delete {
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline:active .e-chip-avatar, .e-chip-list .e-chip.e-outline:active .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.54);
}
.e-chip-list.e-chip.e-outline.e-disabled, .e-chip-list .e-chip.e-outline.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-outline.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-outline.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-outline.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-outline.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-outline.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-outline.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-primary, .e-chip-list .e-chip.e-primary {
  background: #e3165b;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary .e-chip-icon,
.e-chip-list.e-chip.e-primary .e-chip-delete, .e-chip-list .e-chip.e-primary .e-chip-icon,
.e-chip-list .e-chip.e-primary .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-primary .e-chip-delete.e-dlt-btn {
  color: rgba(255, 255, 255, 0.8);
}
.e-chip-list.e-chip.e-primary .e-chip-avatar, .e-chip-list .e-chip.e-primary .e-chip-avatar {
  background-color: #ab1145;
  color: #fff;
}
.e-chip-list.e-chip.e-primary:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-primary:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #fff;
}
.e-chip-list.e-chip.e-primary:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-primary:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-primary:hover, .e-chip-list .e-chip.e-primary:hover {
  background: #ec3673;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary:hover .e-chip-icon,
.e-chip-list.e-chip.e-primary:hover .e-chip-delete, .e-chip-list .e-chip.e-primary:hover .e-chip-icon,
.e-chip-list .e-chip.e-primary:hover .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary:hover .e-chip-avatar, .e-chip-list .e-chip.e-primary:hover .e-chip-avatar {
  background-color: #d01454;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-focused, .e-chip-list .e-chip.e-primary.e-focused {
  background: #ed4980;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-focused .e-chip-delete, .e-chip-list .e-chip.e-primary.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-focused .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-focused.e-active, .e-chip-list .e-chip.e-primary.e-focused.e-active {
  background: #ed4980;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-primary.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-focused.e-active .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-active, .e-chip-list .e-chip.e-primary.e-active {
  background: #ef5b8d;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-active .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-active .e-chip-delete, .e-chip-list .e-chip.e-primary.e-active .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-active .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-active .e-chip-avatar {
  background-color: #ea2466;
  color: #fff;
}
.e-chip-list.e-chip.e-primary:active, .e-chip-list .e-chip.e-primary:active {
  background: #ef5b8d;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary:active .e-chip-icon,
.e-chip-list.e-chip.e-primary:active .e-chip-delete, .e-chip-list .e-chip.e-primary:active .e-chip-icon,
.e-chip-list .e-chip.e-primary:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary:active .e-chip-avatar, .e-chip-list .e-chip.e-primary:active .e-chip-avatar {
  background-color: #ea2466;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-disabled, .e-chip-list .e-chip.e-primary.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-primary.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-primary.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-primary.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-primary.e-outline, .e-chip-list .e-chip.e-primary.e-outline {
  background: transparent;
  border-color: #e3165b;
  color: #e3165b;
}
.e-chip-list.e-chip.e-primary.e-outline .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline .e-chip-delete, .e-chip-list .e-chip.e-primary.e-outline .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline .e-chip-delete {
  color: #e3165b;
}
.e-chip-list.e-chip.e-primary.e-outline .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-primary.e-outline .e-chip-delete.e-dlt-btn {
  color: rgba(227, 22, 91, 0.8);
}
.e-chip-list.e-chip.e-primary.e-outline .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-outline .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-primary.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #e3165b;
}
.e-chip-list.e-chip.e-primary.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-primary.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline:hover, .e-chip-list .e-chip.e-primary.e-outline:hover {
  background: rgba(227, 22, 91, 0.12);
  border-color: #e3165b;
  color: #e3165b;
}
.e-chip-list.e-chip.e-primary.e-outline:hover .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline:hover .e-chip-delete, .e-chip-list .e-chip.e-primary.e-outline:hover .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline:hover .e-chip-delete {
  color: #e3165b;
}
.e-chip-list.e-chip.e-primary.e-outline:hover .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-outline:hover .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-focused, .e-chip-list .e-chip.e-primary.e-outline.e-focused {
  background: #ed4980;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline.e-focused .e-chip-delete, .e-chip-list .e-chip.e-primary.e-outline.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-outline.e-focused .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-focused.e-active, .e-chip-list .e-chip.e-primary.e-outline.e-focused.e-active {
  background: #ed4980;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-primary.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-outline.e-focused.e-active .e-chip-avatar {
  background-color: #e3165b;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-active, .e-chip-list .e-chip.e-primary.e-outline.e-active {
  background: #ef5b8d;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-active .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline.e-active .e-chip-delete, .e-chip-list .e-chip.e-primary.e-outline.e-active .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-active .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-outline.e-active .e-chip-avatar {
  background-color: #ea2466;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline:active, .e-chip-list .e-chip.e-primary.e-outline:active {
  background: #ef5b8d;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline:active .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline:active .e-chip-delete, .e-chip-list .e-chip.e-primary.e-outline:active .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline:active .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-outline:active .e-chip-avatar {
  background-color: #ea2466;
  color: #fff;
}
.e-chip-list.e-chip.e-primary.e-outline.e-disabled, .e-chip-list .e-chip.e-primary.e-outline.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-primary.e-outline.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-primary.e-outline.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-primary.e-outline.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-primary.e-outline.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-primary.e-outline.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-primary.e-outline.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-success, .e-chip-list .e-chip.e-success {
  background: #4d841d;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success .e-chip-icon,
.e-chip-list.e-chip.e-success .e-chip-delete, .e-chip-list .e-chip.e-success .e-chip-icon,
.e-chip-list .e-chip.e-success .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-success .e-chip-delete.e-dlt-btn {
  color: rgba(255, 255, 255, 0.8);
}
.e-chip-list.e-chip.e-success .e-chip-avatar, .e-chip-list .e-chip.e-success .e-chip-avatar {
  background-color: #305212;
  color: #fff;
}
.e-chip-list.e-chip.e-success:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-success:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #fff;
}
.e-chip-list.e-chip.e-success:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-success:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-success:hover, .e-chip-list .e-chip.e-success:hover {
  background: #61a524;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success:hover .e-chip-icon,
.e-chip-list.e-chip.e-success:hover .e-chip-delete, .e-chip-list .e-chip.e-success:hover .e-chip-icon,
.e-chip-list .e-chip.e-success:hover .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success:hover .e-chip-avatar, .e-chip-list .e-chip.e-success:hover .e-chip-avatar {
  background-color: #437319;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-focused, .e-chip-list .e-chip.e-success.e-focused {
  background: #6ab628;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-success.e-focused .e-chip-delete, .e-chip-list .e-chip.e-success.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-success.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-success.e-focused .e-chip-avatar {
  background-color: #4d841d;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-focused.e-active, .e-chip-list .e-chip.e-success.e-focused.e-active {
  background: #6ab628;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-success.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-success.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-success.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-success.e-focused.e-active .e-chip-avatar {
  background-color: #4d841d;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-active, .e-chip-list .e-chip.e-success.e-active {
  background: #74c72c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-active .e-chip-icon,
.e-chip-list.e-chip.e-success.e-active .e-chip-delete, .e-chip-list .e-chip.e-success.e-active .e-chip-icon,
.e-chip-list .e-chip.e-success.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-active .e-chip-avatar, .e-chip-list .e-chip.e-success.e-active .e-chip-avatar {
  background-color: #579521;
  color: #fff;
}
.e-chip-list.e-chip.e-success:active, .e-chip-list .e-chip.e-success:active {
  background: #74c72c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success:active .e-chip-icon,
.e-chip-list.e-chip.e-success:active .e-chip-delete, .e-chip-list .e-chip.e-success:active .e-chip-icon,
.e-chip-list .e-chip.e-success:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success:active .e-chip-avatar, .e-chip-list .e-chip.e-success:active .e-chip-avatar {
  background-color: #579521;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-disabled, .e-chip-list .e-chip.e-success.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-success.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-success.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-success.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-success.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-success.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-success.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-success.e-outline, .e-chip-list .e-chip.e-success.e-outline {
  background: transparent;
  border-color: #4d841d;
  color: #4d841d;
}
.e-chip-list.e-chip.e-success.e-outline .e-chip-icon,
.e-chip-list.e-chip.e-success.e-outline .e-chip-delete, .e-chip-list .e-chip.e-success.e-outline .e-chip-icon,
.e-chip-list .e-chip.e-success.e-outline .e-chip-delete {
  color: #4d841d;
}
.e-chip-list.e-chip.e-success.e-outline .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-success.e-outline .e-chip-delete.e-dlt-btn {
  color: rgba(77, 132, 29, 0.8);
}
.e-chip-list.e-chip.e-success.e-outline .e-chip-avatar, .e-chip-list .e-chip.e-success.e-outline .e-chip-avatar {
  background-color: #4d841d;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-success.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #4d841d;
}
.e-chip-list.e-chip.e-success.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-success.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline:hover, .e-chip-list .e-chip.e-success.e-outline:hover {
  background: rgba(77, 132, 29, 0.12);
  border-color: #4d841d;
  color: #4d841d;
}
.e-chip-list.e-chip.e-success.e-outline:hover .e-chip-icon,
.e-chip-list.e-chip.e-success.e-outline:hover .e-chip-delete, .e-chip-list .e-chip.e-success.e-outline:hover .e-chip-icon,
.e-chip-list .e-chip.e-success.e-outline:hover .e-chip-delete {
  color: #4d841d;
}
.e-chip-list.e-chip.e-success.e-outline:hover .e-chip-avatar, .e-chip-list .e-chip.e-success.e-outline:hover .e-chip-avatar {
  background-color: #4d841d;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-focused, .e-chip-list .e-chip.e-success.e-outline.e-focused {
  background: #6ab628;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-success.e-outline.e-focused .e-chip-delete, .e-chip-list .e-chip.e-success.e-outline.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-success.e-outline.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-success.e-outline.e-focused .e-chip-avatar {
  background-color: #4d841d;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-focused.e-active, .e-chip-list .e-chip.e-success.e-outline.e-focused.e-active {
  background: #6ab628;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-success.e-outline.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-success.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-success.e-outline.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-success.e-outline.e-focused.e-active .e-chip-avatar {
  background-color: #4d841d;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-active, .e-chip-list .e-chip.e-success.e-outline.e-active {
  background: #74c72c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-active .e-chip-icon,
.e-chip-list.e-chip.e-success.e-outline.e-active .e-chip-delete, .e-chip-list .e-chip.e-success.e-outline.e-active .e-chip-icon,
.e-chip-list .e-chip.e-success.e-outline.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-active .e-chip-avatar, .e-chip-list .e-chip.e-success.e-outline.e-active .e-chip-avatar {
  background-color: #579521;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline:active, .e-chip-list .e-chip.e-success.e-outline:active {
  background: #74c72c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline:active .e-chip-icon,
.e-chip-list.e-chip.e-success.e-outline:active .e-chip-delete, .e-chip-list .e-chip.e-success.e-outline:active .e-chip-icon,
.e-chip-list .e-chip.e-success.e-outline:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline:active .e-chip-avatar, .e-chip-list .e-chip.e-success.e-outline:active .e-chip-avatar {
  background-color: #579521;
  color: #fff;
}
.e-chip-list.e-chip.e-success.e-outline.e-disabled, .e-chip-list .e-chip.e-success.e-outline.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-success.e-outline.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-success.e-outline.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-success.e-outline.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-success.e-outline.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-success.e-outline.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-success.e-outline.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-info, .e-chip-list .e-chip.e-info {
  background: #0378d5;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info .e-chip-icon,
.e-chip-list.e-chip.e-info .e-chip-delete, .e-chip-list .e-chip.e-info .e-chip-icon,
.e-chip-list .e-chip.e-info .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-info .e-chip-delete.e-dlt-btn {
  color: rgba(255, 255, 255, 0.8);
}
.e-chip-list.e-chip.e-info .e-chip-avatar, .e-chip-list .e-chip.e-info .e-chip-avatar {
  background-color: #025699;
  color: #fff;
}
.e-chip-list.e-chip.e-info:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-info:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #fff;
}
.e-chip-list.e-chip.e-info:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-info:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-info:hover, .e-chip-list .e-chip.e-info:hover {
  background: #058efb;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info:hover .e-chip-icon,
.e-chip-list.e-chip.e-info:hover .e-chip-delete, .e-chip-list .e-chip.e-info:hover .e-chip-icon,
.e-chip-list .e-chip.e-info:hover .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info:hover .e-chip-avatar, .e-chip-list .e-chip.e-info:hover .e-chip-avatar {
  background-color: #036dc1;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-focused, .e-chip-list .e-chip.e-info.e-focused {
  background: #1998fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-info.e-focused .e-chip-delete, .e-chip-list .e-chip.e-info.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-info.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-info.e-focused .e-chip-avatar {
  background-color: #0378d5;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-focused.e-active, .e-chip-list .e-chip.e-info.e-focused.e-active {
  background: #1998fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-info.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-info.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-info.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-info.e-focused.e-active .e-chip-avatar {
  background-color: #0378d5;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-active, .e-chip-list .e-chip.e-info.e-active {
  background: #2ea1fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-active .e-chip-icon,
.e-chip-list.e-chip.e-info.e-active .e-chip-delete, .e-chip-list .e-chip.e-info.e-active .e-chip-icon,
.e-chip-list .e-chip.e-info.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-active .e-chip-avatar, .e-chip-list .e-chip.e-info.e-active .e-chip-avatar {
  background-color: #0383e9;
  color: #fff;
}
.e-chip-list.e-chip.e-info:active, .e-chip-list .e-chip.e-info:active {
  background: #2ea1fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info:active .e-chip-icon,
.e-chip-list.e-chip.e-info:active .e-chip-delete, .e-chip-list .e-chip.e-info:active .e-chip-icon,
.e-chip-list .e-chip.e-info:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info:active .e-chip-avatar, .e-chip-list .e-chip.e-info:active .e-chip-avatar {
  background-color: #0383e9;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-disabled, .e-chip-list .e-chip.e-info.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-info.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-info.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-info.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-info.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-info.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-info.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-info.e-outline, .e-chip-list .e-chip.e-info.e-outline {
  background: transparent;
  border-color: #0378d5;
  color: #0378d5;
}
.e-chip-list.e-chip.e-info.e-outline .e-chip-icon,
.e-chip-list.e-chip.e-info.e-outline .e-chip-delete, .e-chip-list .e-chip.e-info.e-outline .e-chip-icon,
.e-chip-list .e-chip.e-info.e-outline .e-chip-delete {
  color: #0378d5;
}
.e-chip-list.e-chip.e-info.e-outline .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-info.e-outline .e-chip-delete.e-dlt-btn {
  color: rgba(3, 120, 213, 0.8);
}
.e-chip-list.e-chip.e-info.e-outline .e-chip-avatar, .e-chip-list .e-chip.e-info.e-outline .e-chip-avatar {
  background-color: #0378d5;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-info.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #0378d5;
}
.e-chip-list.e-chip.e-info.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-info.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline:hover, .e-chip-list .e-chip.e-info.e-outline:hover {
  background: rgba(3, 120, 213, 0.12);
  border-color: #0378d5;
  color: #0378d5;
}
.e-chip-list.e-chip.e-info.e-outline:hover .e-chip-icon,
.e-chip-list.e-chip.e-info.e-outline:hover .e-chip-delete, .e-chip-list .e-chip.e-info.e-outline:hover .e-chip-icon,
.e-chip-list .e-chip.e-info.e-outline:hover .e-chip-delete {
  color: #0378d5;
}
.e-chip-list.e-chip.e-info.e-outline:hover .e-chip-avatar, .e-chip-list .e-chip.e-info.e-outline:hover .e-chip-avatar {
  background-color: #0378d5;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-focused, .e-chip-list .e-chip.e-info.e-outline.e-focused {
  background: #1998fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-info.e-outline.e-focused .e-chip-delete, .e-chip-list .e-chip.e-info.e-outline.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-info.e-outline.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-info.e-outline.e-focused .e-chip-avatar {
  background-color: #0378d5;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-focused.e-active, .e-chip-list .e-chip.e-info.e-outline.e-focused.e-active {
  background: #1998fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-info.e-outline.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-info.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-info.e-outline.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-info.e-outline.e-focused.e-active .e-chip-avatar {
  background-color: #0378d5;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-active, .e-chip-list .e-chip.e-info.e-outline.e-active {
  background: #2ea1fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-active .e-chip-icon,
.e-chip-list.e-chip.e-info.e-outline.e-active .e-chip-delete, .e-chip-list .e-chip.e-info.e-outline.e-active .e-chip-icon,
.e-chip-list .e-chip.e-info.e-outline.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-active .e-chip-avatar, .e-chip-list .e-chip.e-info.e-outline.e-active .e-chip-avatar {
  background-color: #0383e9;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline:active, .e-chip-list .e-chip.e-info.e-outline:active {
  background: #2ea1fc;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline:active .e-chip-icon,
.e-chip-list.e-chip.e-info.e-outline:active .e-chip-delete, .e-chip-list .e-chip.e-info.e-outline:active .e-chip-icon,
.e-chip-list .e-chip.e-info.e-outline:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline:active .e-chip-avatar, .e-chip-list .e-chip.e-info.e-outline:active .e-chip-avatar {
  background-color: #0383e9;
  color: #fff;
}
.e-chip-list.e-chip.e-info.e-outline.e-disabled, .e-chip-list .e-chip.e-info.e-outline.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-info.e-outline.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-info.e-outline.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-info.e-outline.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-info.e-outline.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-info.e-outline.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-info.e-outline.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-warning, .e-chip-list .e-chip.e-warning {
  background: #c15700;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning .e-chip-icon,
.e-chip-list.e-chip.e-warning .e-chip-delete, .e-chip-list .e-chip.e-warning .e-chip-icon,
.e-chip-list .e-chip.e-warning .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-warning .e-chip-delete.e-dlt-btn {
  color: rgba(255, 255, 255, 0.8);
}
.e-chip-list.e-chip.e-warning .e-chip-avatar, .e-chip-list .e-chip.e-warning .e-chip-avatar {
  background-color: #843b00;
  color: #fff;
}
.e-chip-list.e-chip.e-warning:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-warning:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #fff;
}
.e-chip-list.e-chip.e-warning:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-warning:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-warning:hover, .e-chip-list .e-chip.e-warning:hover {
  background: #ea6900;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning:hover .e-chip-icon,
.e-chip-list.e-chip.e-warning:hover .e-chip-delete, .e-chip-list .e-chip.e-warning:hover .e-chip-icon,
.e-chip-list .e-chip.e-warning:hover .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning:hover .e-chip-avatar, .e-chip-list .e-chip.e-warning:hover .e-chip-avatar {
  background-color: #ad4e00;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-focused, .e-chip-list .e-chip.e-warning.e-focused {
  background: #fe7300;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-focused .e-chip-delete, .e-chip-list .e-chip.e-warning.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-focused .e-chip-avatar {
  background-color: #c15700;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-focused.e-active, .e-chip-list .e-chip.e-warning.e-focused.e-active {
  background: #fe7300;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-warning.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-focused.e-active .e-chip-avatar {
  background-color: #c15700;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-active, .e-chip-list .e-chip.e-warning.e-active {
  background: #ff7e14;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-active .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-active .e-chip-delete, .e-chip-list .e-chip.e-warning.e-active .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-active .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-active .e-chip-avatar {
  background-color: #d56000;
  color: #fff;
}
.e-chip-list.e-chip.e-warning:active, .e-chip-list .e-chip.e-warning:active {
  background: #ff7e14;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning:active .e-chip-icon,
.e-chip-list.e-chip.e-warning:active .e-chip-delete, .e-chip-list .e-chip.e-warning:active .e-chip-icon,
.e-chip-list .e-chip.e-warning:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning:active .e-chip-avatar, .e-chip-list .e-chip.e-warning:active .e-chip-avatar {
  background-color: #d56000;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-disabled, .e-chip-list .e-chip.e-warning.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-warning.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-warning.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-warning.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-warning.e-outline, .e-chip-list .e-chip.e-warning.e-outline {
  background: transparent;
  border-color: #c15700;
  color: #c15700;
}
.e-chip-list.e-chip.e-warning.e-outline .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-outline .e-chip-delete, .e-chip-list .e-chip.e-warning.e-outline .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-outline .e-chip-delete {
  color: #c15700;
}
.e-chip-list.e-chip.e-warning.e-outline .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-warning.e-outline .e-chip-delete.e-dlt-btn {
  color: rgba(193, 87, 0, 0.8);
}
.e-chip-list.e-chip.e-warning.e-outline .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-outline .e-chip-avatar {
  background-color: #c15700;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-warning.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #c15700;
}
.e-chip-list.e-chip.e-warning.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-warning.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline:hover, .e-chip-list .e-chip.e-warning.e-outline:hover {
  background: rgba(193, 87, 0, 0.12);
  border-color: #c15700;
  color: #c15700;
}
.e-chip-list.e-chip.e-warning.e-outline:hover .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-outline:hover .e-chip-delete, .e-chip-list .e-chip.e-warning.e-outline:hover .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-outline:hover .e-chip-delete {
  color: #c15700;
}
.e-chip-list.e-chip.e-warning.e-outline:hover .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-outline:hover .e-chip-avatar {
  background-color: #c15700;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-focused, .e-chip-list .e-chip.e-warning.e-outline.e-focused {
  background: #fe7300;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-outline.e-focused .e-chip-delete, .e-chip-list .e-chip.e-warning.e-outline.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-outline.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-outline.e-focused .e-chip-avatar {
  background-color: #c15700;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-focused.e-active, .e-chip-list .e-chip.e-warning.e-outline.e-focused.e-active {
  background: #fe7300;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-outline.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-warning.e-outline.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-outline.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-outline.e-focused.e-active .e-chip-avatar {
  background-color: #c15700;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-active, .e-chip-list .e-chip.e-warning.e-outline.e-active {
  background: #ff7e14;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-active .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-outline.e-active .e-chip-delete, .e-chip-list .e-chip.e-warning.e-outline.e-active .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-outline.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-active .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-outline.e-active .e-chip-avatar {
  background-color: #d56000;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline:active, .e-chip-list .e-chip.e-warning.e-outline:active {
  background: #ff7e14;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline:active .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-outline:active .e-chip-delete, .e-chip-list .e-chip.e-warning.e-outline:active .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-outline:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline:active .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-outline:active .e-chip-avatar {
  background-color: #d56000;
  color: #fff;
}
.e-chip-list.e-chip.e-warning.e-outline.e-disabled, .e-chip-list .e-chip.e-warning.e-outline.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-warning.e-outline.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-warning.e-outline.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-warning.e-outline.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-warning.e-outline.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-warning.e-outline.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-warning.e-outline.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-danger, .e-chip-list .e-chip.e-danger {
  background: #d64113;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger .e-chip-icon,
.e-chip-list.e-chip.e-danger .e-chip-delete, .e-chip-list .e-chip.e-danger .e-chip-icon,
.e-chip-list .e-chip.e-danger .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-danger .e-chip-delete.e-dlt-btn {
  color: rgba(255, 255, 255, 0.8);
}
.e-chip-list.e-chip.e-danger .e-chip-avatar, .e-chip-list .e-chip.e-danger .e-chip-avatar {
  background-color: #9e300e;
  color: #fff;
}
.e-chip-list.e-chip.e-danger:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-danger:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #fff;
}
.e-chip-list.e-chip.e-danger:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-danger:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-danger:hover, .e-chip-list .e-chip.e-danger:hover {
  background: #ec5526;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger:hover .e-chip-icon,
.e-chip-list.e-chip.e-danger:hover .e-chip-delete, .e-chip-list .e-chip.e-danger:hover .e-chip-icon,
.e-chip-list .e-chip.e-danger:hover .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger:hover .e-chip-avatar, .e-chip-list .e-chip.e-danger:hover .e-chip-avatar {
  background-color: #c33b11;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-focused, .e-chip-list .e-chip.e-danger.e-focused {
  background: #ed6339;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-focused .e-chip-delete, .e-chip-list .e-chip.e-danger.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-focused .e-chip-avatar {
  background-color: #d64113;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-focused.e-active, .e-chip-list .e-chip.e-danger.e-focused.e-active {
  background: #ed6339;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-danger.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-focused.e-active .e-chip-avatar {
  background-color: #d64113;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-active, .e-chip-list .e-chip.e-danger.e-active {
  background: #ef724c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-active .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-active .e-chip-delete, .e-chip-list .e-chip.e-danger.e-active .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-active .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-active .e-chip-avatar {
  background-color: #e94715;
  color: #fff;
}
.e-chip-list.e-chip.e-danger:active, .e-chip-list .e-chip.e-danger:active {
  background: #ef724c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger:active .e-chip-icon,
.e-chip-list.e-chip.e-danger:active .e-chip-delete, .e-chip-list .e-chip.e-danger:active .e-chip-icon,
.e-chip-list .e-chip.e-danger:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger:active .e-chip-avatar, .e-chip-list .e-chip.e-danger:active .e-chip-avatar {
  background-color: #e94715;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-disabled, .e-chip-list .e-chip.e-danger.e-disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-danger.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-danger.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-danger.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-danger.e-outline, .e-chip-list .e-chip.e-danger.e-outline {
  background: transparent;
  border-color: #d64113;
  color: #d64113;
}
.e-chip-list.e-chip.e-danger.e-outline .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-outline .e-chip-delete, .e-chip-list .e-chip.e-danger.e-outline .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-outline .e-chip-delete {
  color: #d64113;
}
.e-chip-list.e-chip.e-danger.e-outline .e-chip-delete.e-dlt-btn, .e-chip-list .e-chip.e-danger.e-outline .e-chip-delete.e-dlt-btn {
  color: rgba(214, 65, 19, 0.8);
}
.e-chip-list.e-chip.e-danger.e-outline .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-outline .e-chip-avatar {
  background-color: #d64113;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover, .e-chip-list .e-chip.e-danger.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:hover {
  color: #d64113;
}
.e-chip-list.e-chip.e-danger.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active, .e-chip-list .e-chip.e-danger.e-outline:not(.e-active) .e-chip-delete.e-dlt-btn:active {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline:hover, .e-chip-list .e-chip.e-danger.e-outline:hover {
  background: rgba(214, 65, 19, 0.12);
  border-color: #d64113;
  color: #d64113;
}
.e-chip-list.e-chip.e-danger.e-outline:hover .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-outline:hover .e-chip-delete, .e-chip-list .e-chip.e-danger.e-outline:hover .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-outline:hover .e-chip-delete {
  color: #d64113;
}
.e-chip-list.e-chip.e-danger.e-outline:hover .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-outline:hover .e-chip-avatar {
  background-color: #d64113;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-focused, .e-chip-list .e-chip.e-danger.e-outline.e-focused {
  background: #ed6339;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-focused .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-outline.e-focused .e-chip-delete, .e-chip-list .e-chip.e-danger.e-outline.e-focused .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-outline.e-focused .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-focused .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-outline.e-focused .e-chip-avatar {
  background-color: #d64113;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-focused.e-focused.e-active, .e-chip-list .e-chip.e-danger.e-outline.e-focused.e-focused.e-active {
  background: #ed6339;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-focused.e-focused.e-active .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-outline.e-focused.e-focused.e-active .e-chip-delete, .e-chip-list .e-chip.e-danger.e-outline.e-focused.e-focused.e-active .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-outline.e-focused.e-focused.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-focused.e-focused.e-active .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-outline.e-focused.e-focused.e-active .e-chip-avatar {
  background-color: #d64113;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-active, .e-chip-list .e-chip.e-danger.e-outline.e-active {
  background: #ef724c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-active .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-outline.e-active .e-chip-delete, .e-chip-list .e-chip.e-danger.e-outline.e-active .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-outline.e-active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-active .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-outline.e-active .e-chip-avatar {
  background-color: #e94715;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline:active, .e-chip-list .e-chip.e-danger.e-outline:active {
  background: #ef724c;
  border-color: transparent;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline:active .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-outline:active .e-chip-delete, .e-chip-list .e-chip.e-danger.e-outline:active .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-outline:active .e-chip-delete {
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline:active .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-outline:active .e-chip-avatar {
  background-color: #e94715;
  color: #fff;
}
.e-chip-list.e-chip.e-danger.e-outline.e-disabled, .e-chip-list .e-chip.e-danger.e-outline.e-disabled {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-danger.e-outline.e-disabled .e-chip-icon,
.e-chip-list.e-chip.e-danger.e-outline.e-disabled .e-chip-delete, .e-chip-list .e-chip.e-danger.e-outline.e-disabled .e-chip-icon,
.e-chip-list .e-chip.e-danger.e-outline.e-disabled .e-chip-delete {
  color: rgba(0, 0, 0, 0.26);
}
.e-chip-list.e-chip.e-danger.e-outline.e-disabled .e-chip-avatar, .e-chip-list .e-chip.e-danger.e-outline.e-disabled .e-chip-avatar {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.26);
}

.e-fab.e-btn {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 9999px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 40px;
  min-width: 40px;
  padding: 0 15px;
  position: absolute;
  z-index: 100000;
}
.e-fab.e-btn .e-btn-icon {
  margin-top: 0;
  font-size: 14px;
}
.e-fab.e-btn.e-icon-btn {
  padding: 0;
}
.e-fab.e-btn.e-fab-fixed {
  position: fixed;
}
.e-fab.e-btn.e-fab-top {
  top: 16px;
}
.e-fab.e-btn.e-fab-top.e-fab-middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.e-fab.e-btn.e-fab-top.e-fab-middle.e-fab-left.e-fab-center {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.e-fab.e-btn.e-fab-bottom {
  bottom: 16px;
}
.e-fab.e-btn.e-fab-left {
  left: 16px;
}
.e-fab.e-btn.e-fab-left.e-fab-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.e-fab.e-btn.e-fab-right {
  right: 16px;
}

.e-rtl.e-fab.e-btn.e-fab-top {
  top: 16px;
}
.e-rtl.e-fab.e-btn.e-fab-top.e-fab-middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.e-rtl.e-fab.e-btn.e-fab-top.e-fab-middle.e-fab-right.e-fab-center {
  right: 50%;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.e-rtl.e-fab.e-btn.e-fab-bottom {
  bottom: 16px;
}
.e-rtl.e-fab.e-btn.e-fab-right {
  right: 16px;
}
.e-rtl.e-fab.e-btn.e-fab-right.e-fab-center {
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.e-rtl.e-fab.e-btn.e-fab-left {
  left: 16px;
}

.e-fab-hidden {
  visibility: hidden;
}

.e-small.e-fab.e-btn,
.e-small .e-fab.e-btn {
  border-radius: 9999px;
  min-height: 32px;
  min-width: 32px;
  padding: 0 11px;
}
.e-small.e-fab.e-btn.e-icon-btn,
.e-small .e-fab.e-btn.e-icon-btn {
  padding: 0;
}
.e-small.e-fab.e-btn .e-btn-icon,
.e-small .e-fab.e-btn .e-btn-icon {
  font-size: 12px;
}

.e-bigger.e-fab.e-btn,
.e-bigger .e-fab.e-btn {
  border-radius: 9999px;
  min-height: 52px;
  min-width: 52px;
  padding: 0 21px;
}
.e-bigger.e-fab.e-btn.e-icon-btn,
.e-bigger .e-fab.e-btn.e-icon-btn {
  padding: 0;
}
.e-bigger.e-fab.e-btn .e-btn-icon,
.e-bigger .e-fab.e-btn .e-btn-icon {
  font-size: 16px;
}

.e-bigger.e-small.e-fab.e-btn,
.e-bigger.e-small .e-fab.e-btn,
.e-bigger .e-small.e-fab.e-btn,
.e-small .e-bigger.e-fab.e-btn {
  border-radius: 9999px;
  min-height: 46px;
  min-width: 46px;
  padding: 0 17px;
}
.e-bigger.e-small.e-fab.e-btn.e-icon-btn,
.e-bigger.e-small .e-fab.e-btn.e-icon-btn,
.e-bigger .e-small.e-fab.e-btn.e-icon-btn,
.e-small .e-bigger.e-fab.e-btn.e-icon-btn {
  padding: 0;
}
.e-bigger.e-small.e-fab.e-btn .e-btn-icon,
.e-bigger.e-small .e-fab.e-btn .e-btn-icon,
.e-bigger .e-small.e-fab.e-btn .e-btn-icon,
.e-small .e-bigger.e-fab.e-btn .e-btn-icon {
  font-size: 14px;
}

.e-fab.e-btn {
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
}
.e-fab.e-btn:hover:not(:focus), .e-fab.e-btn:active, .e-fab.e-btn.e-active, .e-fab.e-btn:disabled {
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
}
.e-fab.e-btn:focus {
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
}

.e-speeddial-popup {
  position: absolute;
  z-index: 100000;
  pointer-events: none;
  /* OVERFLOW CSS */
}
.e-speeddial-popup.e-speeddial-top {
  top: 16px;
}
.e-speeddial-popup.e-speeddial-top.e-speeddial-radial .e-speeddial-li {
  top: 0;
}
.e-speeddial-popup.e-speeddial-top.e-speeddial-radial.e-speeddial-middle .e-speeddial-li {
  top: calc(0.5 * var(--speeddialRadialMinWidth) + var(--speeddialRadialOffset));
}
.e-speeddial-popup.e-speeddial-top.e-speeddial-middle, .e-speeddial-popup.e-speeddial-top.e-speeddial-linear {
  top: var(--speeddialVertDist);
}
.e-speeddial-popup.e-speeddial-bottom {
  bottom: 16px;
}
.e-speeddial-popup.e-speeddial-bottom.e-speeddial-radial .e-speeddial-li {
  bottom: 0;
}
.e-speeddial-popup.e-speeddial-bottom.e-speeddial-radial.e-speeddial-middle .e-speeddial-li {
  bottom: calc(0.5 * var(--speeddialRadialMinWidth) + var(--speeddialRadialOffset));
}
.e-speeddial-popup.e-speeddial-bottom.e-speeddial-middle, .e-speeddial-popup.e-speeddial-bottom.e-speeddial-linear {
  bottom: var(--speeddialVertDist);
}
.e-speeddial-popup.e-speeddial-left {
  left: var(--speeddialRadialHorzDist);
}
.e-speeddial-popup.e-speeddial-left.e-speeddial-radial .e-speeddial-li {
  left: 0;
}
.e-speeddial-popup.e-speeddial-left.e-speeddial-radial.e-speeddial-center .e-speeddial-li {
  left: calc(0.5 * var(--speeddialRadialMinWidth) + var(--speeddialRadialOffset));
}
.e-speeddial-popup.e-speeddial-left.e-speeddial-radial.e-speeddial-center, .e-speeddial-popup.e-speeddial-left.e-speeddial-center {
  left: var(--speeddialRadialHorzDist);
}
.e-speeddial-popup.e-speeddial-left.e-speeddial-linear {
  left: var(--speeddialHorzDist);
}
.e-speeddial-popup.e-speeddial-right {
  right: var(--speeddialRadialHorzDist);
}
.e-speeddial-popup.e-speeddial-right.e-speeddial-radial .e-speeddial-li {
  right: 0;
}
.e-speeddial-popup.e-speeddial-right.e-speeddial-radial.e-speeddial-center .e-speeddial-li {
  right: calc(0.5 * var(--speeddialRadialMinWidth) + var(--speeddialRadialOffset));
}
.e-speeddial-popup.e-speeddial-right.e-speeddial-radial.e-speeddial-center, .e-speeddial-popup.e-speeddial-right.e-speeddial-center {
  right: var(--speeddialRadialHorzDist);
}
.e-speeddial-popup.e-speeddial-right.e-speeddial-linear {
  right: var(--speeddialHorzDist);
}
.e-speeddial-popup.e-speeddial-template {
  outline: none;
  pointer-events: auto;
  z-index: 100001;
}
.e-speeddial-popup.e-speeddial-linear .e-speeddial-li {
  margin: 5px 0;
}
.e-speeddial-popup .e-speeddial-ul {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 5px 5px;
}
.e-speeddial-popup .e-speeddial-li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  pointer-events: auto;
  position: relative;
  z-index: 100001;
}
.e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text {
  margin: 0 8px;
}
.e-speeddial-popup .e-speeddial-li-text {
  border-radius: 2px;
  padding: 0 5px;
  white-space: nowrap;
  height: 26px;
  line-height: 26px;
  border: 1px solid;
}
.e-speeddial-popup .e-speeddial-li-icon {
  border-radius: 99999px;
  border: 1px solid;
  font-size: 12px;
  height: 28px;
  width: 28px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.e-speeddial-popup.e-speeddial-fixed {
  position: fixed;
}
.e-speeddial-popup.e-speeddial-vert-right .e-speeddial-li, .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul, .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul.e-speeddial-horz-top {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.e-speeddial-popup.e-speeddial-vert-bottom .e-speeddial-ul {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul.e-speeddial-horz-top, .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul, .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul {
  padding: 5px 5px;
}
.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li, .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li {
  margin: 0 5px;
}
.e-speeddial-popup.e-speeddial-overflow .e-speeddial-ul {
  overflow: auto;
  scroll-behavior: smooth;
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
}
.e-speeddial-popup.e-speeddial-overflow .e-speeddial-ul::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}
.e-speeddial-popup.e-speeddial-overflow.e-speeddial-vert-overflow .e-speeddial-ul {
  max-height: var(--speeddialOverflowLimit);
}
.e-speeddial-popup.e-speeddial-overflow.e-speeddial-horz-overflow .e-speeddial-ul {
  max-width: var(--speeddialOverflowLimit);
}
.e-speeddial-popup.e-speeddial-radial {
  height: calc(var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinHeight));
  width: calc(var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinWidth));
}
.e-speeddial-popup.e-speeddial-radial.e-speeddial-middle {
  height: calc(2 * var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinHeight));
}
.e-speeddial-popup.e-speeddial-radial.e-speeddial-center {
  width: calc(2 * var(--speeddialRadialOffset) + 2 * var(--speeddialRadialMinWidth));
}
.e-speeddial-popup.e-speeddial-radial.e-speeddial-top-left {
  border-bottom-right-radius: 100%;
}
.e-speeddial-popup.e-speeddial-radial.e-speeddial-top-right {
  border-bottom-left-radius: 100%;
}
.e-speeddial-popup.e-speeddial-radial.e-speeddial-bottom-left {
  border-top-right-radius: 100%;
}
.e-speeddial-popup.e-speeddial-radial.e-speeddial-bottom-right {
  border-top-left-radius: 100%;
}
.e-speeddial-popup.e-speeddial-radial .e-speeddial-ul {
  height: inherit;
  width: inherit;
  position: relative;
  border-radius: inherit;
  padding: 0;
}
.e-speeddial-popup.e-speeddial-radial .e-speeddial-li {
  position: absolute;
  -webkit-transform: rotate(var(--speeddialRadialAngle)) translate(var(--speeddialRadialOffset)) rotate(calc(-1 * var(--speeddialRadialAngle)));
          transform: rotate(var(--speeddialRadialAngle)) translate(var(--speeddialRadialOffset)) rotate(calc(-1 * var(--speeddialRadialAngle)));
}
.e-speeddial-popup.e-speeddial-radial .e-speeddial-li-text, .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li-text, .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li-text {
  position: absolute;
  visibility: hidden;
}

.e-small.e-speeddial-popup .e-speeddial-ul,
.e-small .e-speeddial-popup .e-speeddial-ul {
  padding: 5px 3px;
}
.e-small.e-speeddial-popup.e-speeddial-linear .e-speeddial-li,
.e-small .e-speeddial-popup.e-speeddial-linear .e-speeddial-li {
  margin: 5px 0;
}
.e-small.e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text,
.e-small .e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text {
  margin: 0 8px;
}
.e-small.e-speeddial-popup .e-speeddial-li-text,
.e-small .e-speeddial-popup .e-speeddial-li-text {
  height: 22px;
  line-height: 22px;
}
.e-small.e-speeddial-popup .e-speeddial-li-icon,
.e-small .e-speeddial-popup .e-speeddial-li-icon {
  height: 24px;
  width: 24px;
  font-size: 12px;
}
.e-small.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul, .e-small.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul,
.e-small .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul,
.e-small .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul {
  padding: 3px 5px;
}
.e-small.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li, .e-small.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li,
.e-small .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li,
.e-small .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li {
  margin: 0 5px;
}

.e-bigger.e-speeddial-popup .e-speeddial-ul,
.e-bigger .e-speeddial-popup .e-speeddial-ul {
  padding: 6px 7px;
}
.e-bigger.e-speeddial-popup.e-speeddial-linear .e-speeddial-li,
.e-bigger .e-speeddial-popup.e-speeddial-linear .e-speeddial-li {
  margin: 6px 0;
}
.e-bigger.e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text,
.e-bigger .e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text {
  margin: 0 12px;
}
.e-bigger.e-speeddial-popup .e-speeddial-li-text,
.e-bigger .e-speeddial-popup .e-speeddial-li-text {
  height: 32px;
  line-height: 32px;
}
.e-bigger.e-speeddial-popup .e-speeddial-li-icon,
.e-bigger .e-speeddial-popup .e-speeddial-li-icon {
  height: 36px;
  width: 36px;
  font-size: 14px;
}
.e-bigger.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul, .e-bigger.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul,
.e-bigger .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul,
.e-bigger .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul {
  padding: 7px 6px;
}
.e-bigger.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li, .e-bigger.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li,
.e-bigger .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li,
.e-bigger .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li {
  margin: 0 6px;
}

.e-bigger.e-small.e-speeddial-popup .e-speeddial-ul,
.e-bigger.e-small .e-speeddial-popup .e-speeddial-ul,
.e-bigger .e-small.e-speeddial-popup .e-speeddial-ul,
.e-small .e-bigger.e-speeddial-popup .e-speeddial-ul {
  padding: 5px 5px;
}
.e-bigger.e-small.e-speeddial-popup.e-speeddial-linear .e-speeddial-li,
.e-bigger.e-small .e-speeddial-popup.e-speeddial-linear .e-speeddial-li,
.e-bigger .e-small.e-speeddial-popup.e-speeddial-linear .e-speeddial-li,
.e-small .e-bigger.e-speeddial-popup.e-speeddial-linear .e-speeddial-li {
  margin: 5px 0;
}
.e-bigger.e-small.e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text,
.e-bigger.e-small .e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text,
.e-bigger .e-small.e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text,
.e-small .e-bigger.e-speeddial-popup .e-speeddial-li:not(.e-speeddial-text-li) .e-speeddial-li-text {
  margin: 0 12px;
}
.e-bigger.e-small.e-speeddial-popup .e-speeddial-li-text,
.e-bigger.e-small .e-speeddial-popup .e-speeddial-li-text,
.e-bigger .e-small.e-speeddial-popup .e-speeddial-li-text,
.e-small .e-bigger.e-speeddial-popup .e-speeddial-li-text {
  height: 30px;
  line-height: 30px;
}
.e-bigger.e-small.e-speeddial-popup .e-speeddial-li-icon,
.e-bigger.e-small .e-speeddial-popup .e-speeddial-li-icon,
.e-bigger .e-small.e-speeddial-popup .e-speeddial-li-icon,
.e-small .e-bigger.e-speeddial-popup .e-speeddial-li-icon {
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.e-bigger.e-small.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul, .e-bigger.e-small.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul,
.e-bigger.e-small .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul,
.e-bigger.e-small .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul,
.e-bigger .e-small.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul,
.e-bigger .e-small.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul,
.e-small .e-bigger.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-ul,
.e-small .e-bigger.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-ul {
  padding: 5px 5px;
}
.e-bigger.e-small.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li, .e-bigger.e-small.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li,
.e-bigger.e-small .e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li,
.e-bigger.e-small .e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li,
.e-bigger .e-small.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li,
.e-bigger .e-small.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li,
.e-small .e-bigger.e-speeddial-popup.e-speeddial-horz-right .e-speeddial-li,
.e-small .e-bigger.e-speeddial-popup.e-speeddial-horz-left .e-speeddial-li {
  margin: 0 5px;
}

.e-speeddial-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99998;
}
.e-speeddial-overlay.e-speeddial-fixed {
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.e-speeddial-hidden {
  visibility: hidden;
}

.e-speeddial-overlay {
  background-color: rgba(107, 114, 128, 0.5);
}

.e-speeddial-popup .e-speeddial-li {
  color: #000;
}
.e-speeddial-popup .e-speeddial-li .e-speeddial-li-text {
  background: #fff;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
  border-color: #fff;
}
.e-speeddial-popup .e-speeddial-li .e-speeddial-li-icon {
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-color: #fff;
}
.e-speeddial-popup .e-speeddial-li.e-disabled {
  color: #000;
}
.e-speeddial-popup .e-speeddial-li.e-disabled .e-speeddial-li-text,
.e-speeddial-popup .e-speeddial-li.e-disabled .e-speeddial-li-icon {
  background: #fafafa;
  border-color: #fafafa;
}
.e-speeddial-popup .e-speeddial-li.e-speeddial-li-active:not(.e-disabled),
.e-speeddial-popup .e-speeddial-li:focus:not(.e-disabled) {
  color: #000;
}
.e-speeddial-popup .e-speeddial-li.e-speeddial-li-active:not(.e-disabled) .e-speeddial-li-text,
.e-speeddial-popup .e-speeddial-li.e-speeddial-li-active:not(.e-disabled) .e-speeddial-li-icon,
.e-speeddial-popup .e-speeddial-li:focus:not(.e-disabled) .e-speeddial-li-text,
.e-speeddial-popup .e-speeddial-li:focus:not(.e-disabled) .e-speeddial-li-icon {
  background: #e0e0e0;
  border-color: #e0e0e0;
}
.e-speeddial-popup .e-speeddial-li:not(.e-disabled):hover {
  color: #000;
}
.e-speeddial-popup .e-speeddial-li:not(.e-disabled):hover .e-speeddial-li-text,
.e-speeddial-popup .e-speeddial-li:not(.e-disabled):hover .e-speeddial-li-icon {
  background: #e0e0e0;
  border-color: #e0e0e0;
}
.e-speeddial-popup .e-speeddial-li:not(.e-disabled):active {
  color: #000;
}
.e-speeddial-popup .e-speeddial-li:not(.e-disabled):active .e-speeddial-li-text,
.e-speeddial-popup .e-speeddial-li:not(.e-disabled):active .e-speeddial-li-icon {
  background: #bdbdbd;
  border-color: #bdbdbd;
}